import React from 'react';
import { Container } from 'reactstrap';
import Footer from '../container/Footer'
import Header from '../container/Header'
import ContentViewerView from '../container/ContentViewer';

const ViewTab = () => {
    return (
        <Container className="px-0">
            <Header />
            <ContentViewerView/>
            <Footer/>
        </Container >
    )
}
export default ViewTab;

