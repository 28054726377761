import React, { Fragment } from 'react';
import {
  Card, CardBody, CardTitle, CardSubtitle, CardText, Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { isBrowser } from 'react-device-detect';
import Center from 'react-center';
import Documentlist from '../container/Documentlist';

const Paging = ({ currentPageNo, pageCount, strings, changePage }) => {
  if (pageCount === 0) return (
    <Card>
    <CardBody>
      <CardTitle tag="h4">{strings.SearchTitle1}</CardTitle>  
      <CardSubtitle tag="h5" className="mb-2 text-muted">{strings.SearchTitle2}</CardSubtitle>
      <CardText><ol>
                    <li>{strings.SearchList1}</li>
                    <li>{strings.SearchList2}</li>
                    <li>{strings.SearchList3}</li>
                    <li>{strings.SearchList4}</li>
                 </ol>
                 {strings.SearchText1}
      </CardText>
      <CardSubtitle tag="h5" className="mb-2 text-muted">{strings.SearchTitle3}</CardSubtitle>
      <CardText>
        {strings.SearchText2}
      </CardText>
      <CardTitle tag="h4">{strings.SearchTitle4}</CardTitle>  
      <CardSubtitle tag="h5" className="mb-2 text-muted">{strings.SearchTitle5}</CardSubtitle>
      <CardText><ol>
                    <li>{strings.SearchList5}</li>
                    <li>{strings.SearchList6}</li>
                    <li>{strings.SearchList7}</li>
                    <li>{strings.SearchList8}</li>
                 </ol>
      </CardText>
    </CardBody>
  </Card>
  )
  else if (isBrowser) 
  return (
    <Fragment>
      <Documentlist />
      <div className="pagination-wrapper">
        <Pagination>
          <PaginationItem disabled={currentPageNo <= 0}>
            <PaginationLink
              onClick={e => changePage(e, currentPageNo - 1)}
              previous
              href="#"
            />
          </PaginationItem>
          {[...Array(pageCount)].map((page, i) =>
            <PaginationItem active={i === currentPageNo} key={i}>
              <PaginationLink onClick={e => changePage(e, i)} href="#">
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem disabled={currentPageNo >= pageCount - 1}>
            <PaginationLink
              onClick={e => changePage(e, currentPageNo + 1)}
              next
              href="#"
            />
          </PaginationItem>
        </Pagination>
      </div>
      </Fragment>
      )
    
      else return ( 
        <div>
          <Center>
            <Documentlist />
          </Center>
        
          <Center>
          <Pagination>
          <PaginationItem disabled={currentPageNo <= 0}>
            <PaginationLink
              onClick={e => changePage(e, currentPageNo - 1)}
              previous
              href="#"
            />
          </PaginationItem>
          {[...Array(pageCount)].map((page, i) =>
            <PaginationItem active={i === currentPageNo} key={i}>
              <PaginationLink onClick={e => changePage(e, i)} href="#">
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem disabled={currentPageNo >= pageCount - 1}>
            <PaginationLink
              onClick={e => changePage(e, currentPageNo + 1)}
              next
              href="#"
            />
          </PaginationItem>
        </Pagination>
          </Center>
  </div>
  );
}


export default Paging;