const en = {
    "Action": "Action",
    "Actions": "Actions",
    "Attribute": "Attribut",
    "Auditlog": "Auditlog",
    "BackToLogin": "Back to login",
    "ChoseFile": "Chose file",
    "Clear": "Clear",
    "Close": "Close",
    "Code": "Code",
    "Company": "Member of a group",
    "ConfirmEmail": "A message for confirmation has been sent to you email",
    "Contact": "Contact",
    "ConfirmPassword": "Confirm password",
    "ConfirmDelete": "Do you really want to delete the document",
    "ConfirmDeleteVersion": "Do you really want to delete the lastest version",
    "Creator" : "Creator",
    "Created_at" : "Created at",
    "DeleteDocument": "Delete document",
    "DeleteVersion": "Delete version",
    "Document": "Document",
    "Documentation": "Documentation",
    "Documenttype": "Document type",
    "Documenttypes": "Document types",
    "Doublecheck": "Double check",
    "Download": "Download",
    "DropFilesHere": "Drop files here or click on 'Chose file'",
    "DropTheFileHere": "Drop the file here",
    "Example": "Exempel",
    "Email": "Email",
    "FailedToUpdate" : "Sorry. We failed to update",
    "FallbackLink": "<p>Your browser does not support inline pdf. Download the document at: <a href='[url]'>download</a></p>",
    "Filename": "File name",
    "Filter": "Filter",
    "FooterText1": "Api-First Document Management System",
    "FooterText2": "For a command-line interface please visit",
    "FooterText3": "OpenAPI definition to be found at",
    "Help": "Help",
    "Language": "Language",
    "LoginFailed": "Failed to sign in. Please try again.",
    "LoginServer": "Login server",
    "LooksGood": "Looks good",
    "Mandatory": "The field is mandatory.",
    "Menu": "Menu",
    "Metadata": "Metadata",
    "MetadataForTheDocuments": "Metadata for the documents",
    "MimeType": "Mime type",
    "Name": "Name",
    "NewPassword": "New password",
    "NewValue": "New value",
    "No": "No",
    "NoRender": "files cannot be rendered here. Please download instead!!!",
    "NotAValidEmail": "Not a valid email",
    "OpenBankid": "Please open Freja to log in",
    "Password": "Password",
    "PasswordsMustBeSame": "The passwords shall be the same",
    "PdfOnly": "Only pdf documents can currently be visualized. Please download the file.",
    "Register": "Register password",
    "RegisterFailed": "Failed to register. Error message:",
    "RegisterHelp": "As a member of a group your group admin will have to add you to the group " + 
                    "and give you proper permissions before you can use the system. " + 
                    "If you are not member of a group you can only work with your own documents, " + 
                    "but can login immediately. Please contact info@tinyds.se if you have questions.",
    "ResetCodeToEmail": "A reset code has been sent to your email",
    "ResetFailed": "Password reset failed",
    "ResetPassword": "Reset password",
    "ResetSuccess": "Password was reset",
    "Search": "Search",
    "SearchList1": "Select a document type",
    "SearchList2": "Select search criteria (metadata)",
    "SearchList3": "Click Search",
    "SearchList4": "In the search result, left-click on a row to see possible actions",
    "SearchList5": "Go to Upload",
    "SearchList6": "Chose metadata",
    "SearchList7": "Drag a document into the uploading zone (or use the file choser)",
    "SearchList8": "Click Send",
    "SearchText1": "For strings it is enough give the beginning of a string. " +
                   "Generic search on all document types will soon be implemented. " + 
                   "Note that only pdf documents can be viewed. More mime types will soon be supported. " +
                   "All types of documents can be downloaded.",
    "SearchText2": "A group will have access to an admin gui where document types, metadata formats and permissions can " +
                   "be defined. For a standalone user document types are defined by TinyDS. To create a group or have a " + 
                   "standalone document type added, please contact us.",
    "SearchTitle1": "Document search",
    "SearchTitle2": "How to search",
    "SearchTitle3": "How document types are defined",
    "SearchTitle4": "Document upload",
    "SearchTitle5": "How to upload",
    "Send": "Send",
    "Sent": "Sent",
    "Server": "Server",
    "SessionExpired": "Session expired. You need to logout and login again.",
    "SignIn": "Sign in",
    "SignOut": "Sign out",
    "SignUp": "Sign up",
    "Size": "Size",
    "ShowDocument": "Show document",
    "Ssn": "Personal number",
    "StepWithButtons": "Step through the search result with the buttons",
    "Time": "Time",
    "TooManyLogins": "Too many requests. You have to wait a little before you log in again",
    "TooltipPrev": "Previous document in the search result",
    "TooltipNext": "Next document in the search result",
    "TooltipHideMetadata": "Hide metadata fields",
    "TooltipShowMetadata": "Show metadata fields",
    "Type": "Type",
    "Update": "Uppdate",
    "UpdateMetadata": "Update metadata",
    "UpdateDocument": "Update document",
    "Upload": "Upload",
    "Username": "Username",
    "UserNameShouldBeEmail": "Username should be an email",
    "UserNameShouldBeSsn": "Twelve digits",
    "View": "DocumentView",
    "Editor": "Editor",
    "ViewLastVersion": "Show last version",
    "ViewTitle": "Document view",
    "ViewList1": "Go to the search tab",
    "ViewList2": "Search a document",
    "ViewList3": "Right-click on a row and select 'View document'",
    "ViewList4": "You will automatically be redirected here",
    "ViewText1": "To see a document's content here",
    "ViewText": "Please note that currently only pdf documents can be viewed here. " +
                "More mime types will soon be possible to see here.",
    "WrongLogin": "Wrong username or password",
    "Yes": "Yes",
    "YourEmail": "Your email"
}

const sv =
{
    "Action": "Åtgärd",
    "Actions": "Åtgärder",
    "Attribute": "Attribut",
    "Auditlog": "Auditlog",
    "BackToLogin": "Tillbaks till inloggningen",
    "ChoseFile": "Välj fil",
    "Clear": "Rensa",
    "Close": "Stäng",
    "Code": "Kod",
    "Company": "Om du tillhör en grupp",
    "ConfirmDelete": "Vill du verkligen ta bort dokumentet",
    "ConfirmDeleteVersion": "Vill du verkligen ta bort senaste versionen",
    "ConfirmEmail": "Ett meddelande för bekräftelse har skickats till din e-post",
    "ConfirmPassword": "Bekräfta lösenord",
    "Contact": "Kontakt",
    "Creator" : "Skapare",
    "Created_at" : "Skapat",
    "DeleteDocument": "Radera dokument",
    "DeleteVersion": "Radera version",
    "Document": "Dokument",
    "Documentation": "Dokumentation",
    "Documenttype": "Dokumenttype",
    "Documenttypes": "Dokumenttyper",
    "Download": "Ladda ner",
    "Doublecheck": "Double check",
    "DropFilesHere": "Släpp filer här eller klicka på 'Välj fil'",
    "DropTheFileHere": "Släpp filen här",
    "Email": "Epost",
    "Example": "Exempel",
    "FailedToUpdate" : "Beklagar. Vi misslyckades uppdatera",
    "FallbackLink": "<p>Din browser stödjer ej pdf-visning. Ladda ned dokumentet på: <a href='[url]'>nedladdningslänk</a></p>",
    "Filename": "Filnamn",
    "Filter": "Filter",
    "FooterText1": "Api-First Document Managment System",
    "FooterText2": "För cli klient vänligen besök",
    "FooterText3": "OpenAPI definition finns på",
    "Help": "Hjälp",
    "Language": "Språk",
    "LoginFailed": "Misslyckdes att logga in. Var god försök igen.",
    "LooksGood": "Ser bra ut",
    "Mandatory": "Fältet är obligatoriskt.",
    "Menu": "Meny",
    "Metadata": "Metadata",
    "MetadataForTheDocuments": "Metadata för dokumenten",
    "MimeType": "Mime type",
    "Name": "Name",
    "NewPassword": "Nytt lösenord",
    "NewValue": "Nytt värde",
    "No": "No",
    "NoRender": "kan ej visas här. Var god ladda ner filen istället!!!",
    "NotAValidEmail": "Inte en korrekt epost-adress",
    "OpenBankid": "Var god öppna Freja för att logga in",
    "Password": "Lösenord",
    "PasswordsMustBeSame": "Lösenorden måste vara lika",  
    "PdfOnly": "Endast pdf-dokument kan för närvarande bli visualiserade. Var god ladda ner filen.",
    "Register": "Registrera lösenord",
    "RegisterFailed": "Misslyckdes att registrera. Felmeddelande:",
    "RegisterHelp": "Som medlem av en grupp skall din gruppadministratör lägga till dig i gruppen " + 
                    "och tilldela dig rättigheter innan du kan börja använda systemet. " + 
                    "Om du inte är medlem i en grupp har du bara tillgång till dina egna dokument, " +
                    "men kan logga in och använda systemet omedelbart. Var god kontakta info@mmdok.se om du har frågor.",
    "ResetCodeToEmail": "En återställningskod har skickats till din epost",
    "ResetFailed": "Återställning av lösenord misslyckades",
    "ResetPassword": "Återställ lösenord",
    "ResetSuccess": "Lösenordet ändrades",
    "Search": "Sök",
    "SearchList1": "Välj dokumenttyp",
    "SearchList2": "Välj sökkriterium (metadata)",
    "SearchList3": "Klicka sök",
    "SearchList4": "I sökresultatet, vänsterklicka på en rad för att se vad du kan göra",
    "SearchList5": "Gå till uppladdningsfliken",
    "SearchList6": "Välj metadata",
    "SearchList7": "Dra in ett dokument i dokumentzonen (eller använd filväljaren)",
    "SearchList8": "Klicka skicka",
    "SearchText1": "För strängar räcker det att ge början på strängen. ",
    "SearchText2": "En grupp får access till ett admin gui där dokumenttyper, metadataformat och rättigheter kan " +
                   "konfigueras. För fristående användare definieras dokumenttyper av TinyDS. För att skapa en grupp " +
                   "eller definiera en dokumenttyp, var vänlig kontakta oss.",
    "SearchTitle1": "Sökning av dokument",
    "SearchTitle2": "Hur man söker",
    "SearchTitle3": "Hur dokumenttyper konfigureras",
    "SearchTitle4": "Uppladdning av dokument",
    "SearchTitle5": "Hur man laddar upp",
    "Send": "Skicka",
    "Sent": "Skickad",
    "Server": "Server",
    "SessionExpired": "Din session har gått ut. Du behöver logga ut och logga in igen.",
    "SignIn": "Logga in",
    "SignOut": "Logga ut",
    "SignUp": "Inloggning",
    "Size": "Storlek",
    "ShowDocument": "Visa dokument",  
    "Ssn": "Personnummer",
    "StepWithButtons": "Stega genom sökresultatet med knapparna",
    "Time": "Tid",
    "TooManyLogins": "För många försök. Du måste vänta en stund innan du loggar in igen.",
    "TooltipPrev": "Föregående dokument i sökresultatet",
    "TooltipNext": "Nästa dokument i sökresultatet",
    "TooltipHideMetadata": "Göm metadatafälten",
    "TooltipShowMetadata": "Visa metadatafälten",
    "Type": "Typ",
    "Update": "Uppdatera",
    "UpdateDocument": "Uppdatera dokument",
    "UpdateMetadata": "Uppdatera metadata",
    "Upload": "Uppladdning",
    "Username": "Användarnamn",
    "UserNameShouldBeEmail": "Användarnamn skall vara en e-postadress",
    "UserNameShouldBeSsn": "Tolv siffror",
    "View": "Dokumentvy",
    "Editor": "Editor",
    "ViewLastVersion": "Se föregående version",
    "ViewTitle": "Dokumentvisning",
    "ViewList1": "Gå till Sökning",
    "ViewList2": "Sök ett dokument",
    "ViewList3": "Högerklocka på en rad och välj 'Visa dokument'",
    "ViewList4": "Du blir automatiskt dirigerad hit",
    "ViewText1": "För att se ett dokument här",
    "ViewText2": "Var god notera att endast pdf-dokument kan visas här. " +
                "Fler mimetyper kommer att stödjas inom kort. Övriga typer kan laddas ner.",
    "WrongLogin": "Fel användarnamn eller lösenord",
    "Yes": "Ja",
    "YourEmail": "Din epost"
}

const getStrings = () => {
    let userLanguage = localStorage.getItem('lang');
    if (!userLanguage) {
         if (window.navigator.userLanguage || window.navigator.language.startsWith('sv'))
         userLanguage = 'sv';
         localStorage.setItem('lang', userLanguage)
    }
    if (userLanguage === 'sv') return sv;
    else return en;
}

export default getStrings;
