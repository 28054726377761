import React, {Fragment} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../container/Footer';
import Header from '../container/Header';
import SearchForm from '../container/SearchForm';
import Paging from '../container/Paging';
import Center from 'react-center';
import { isBrowser } from 'react-device-detect';

const SearchTab = () => {
    const getMobileView = () => {
        return (
            <Fragment>
                <Center>
                    <SearchForm />
                </Center>
                <Center>
                    <Paging />
                </Center>
            </Fragment>
        )
    }

    const getBrowserView = () => {
        return (
            <Row noGutters className="pt-2 pt-md-5 w-100 px-4 px-xl-0 mb-3 position-relative">
                <Col key={1} xs={{ order: 1 }} md={{ size: 2, order: 1 }} tag="aside" className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0">
                    <SearchForm />
                </Col>
                <Col key={2} xs={{ order: 2 }} md={{ size: 9, offset: 1 }} tag="section" className="py-5 mb-5 py-md-0 mb-md-0">
                    <Paging />
                </Col>
            </Row>
        )
    }

    const getView = () => {
        return isBrowser? getBrowserView() : getMobileView();
    }

    return (
        <Container className="px-0">
            <Header />
            {getView()}
            <Footer />
        </Container>
    )
}


export default SearchTab;
