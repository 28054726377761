import { connect } from 'react-redux'
import ContentEditor from '../presentation/ContentEditor'
import { setCurrentDocument } from '../../actions/DocumentAction'
import { NEW_DOCUMENT_ACTION } from '../../constants';

const mapStateToProps = (state) => {
  let o = [];
  if (state.documents.url != null)
    o = { url: state.documents.url, metadata: state.documents.metadata };
  const isHtml = (state.documents.metadata != null && state.documents.metadata.Mimetype != null 
                  &&  state.documents.metadata.Mimetype.value === "text/html");
  o.isExistingTinyDoc = (state.tab.action == null || state.tab.action != NEW_DOCUMENT_ACTION) && isHtml;
  o.strings = state.i18.strings;
  return o;
}

const mapDispatchToProps = dispatch => {
  return {
    updateUrl: (metadata) => {
      dispatch(setCurrentDocument(metadata));
    }
  }
}

const ContentEditorView = connect(mapStateToProps, mapDispatchToProps)(ContentEditor);

export default ContentEditorView;