import React, { Component } from 'react';
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup } from 'reactstrap';
import { Label, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import HttpClient from '../../utils/HttpClient';

export default class EditMetadataModal extends Component {

  httpClient = new HttpClient();

  constructor(props) {
    super(props);
    this.metadata = this.props.metadata;
    this.strings = this.props.strings;
    this.state = {
      modal: false,
      size: 'modal-sm',
      body: '',
      dropdownOpen: false,
      attrDisplayName: this.strings['Attribute'],
      attrName: '',
      attrValue: ''
    };
  }

  toggle = () => {
    const d = this.metadata;
    this.setState({
      modal: !this.state.modal,
    });
    this.docid = d['id'].value;
  }

  toggleButton = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onFormSubmit = async () => {
    const value = document.getElementById('value').value;
    const name = this.state.attrName;
    const displayName = this.state.attrDisplayName;
    this.hide();

    if (name && this.metadata[displayName] && displayName !== this.strings['Attribute']) {
      this.metadata[displayName].value = value;     
      await this.httpClient.update(this.docid, name, value, () => {
        this.props.updateDocument(this.metadata);
      });
    }
    else alert(this.strings['FailedToUpdate'] + ": " + displayName);
  }

  hide = () => {
    this.setState({
      modal: false
    });
  }

  getDisplayName = item => {
    return localStorage.getItem('lang') === 'sv'?
        item.displayname_sv : item.displayname;
  }

  select = (event) => {
    const p = event.target.innerText;
    this.setState({
      attrDisplayName: this.getDisplayName(this.metadata[p]),
      attrName: this.metadata[p].name,
      attrValue: this.metadata[p].value,
      attrId: this.metadata[p].id
    });
  }

  getAttributes = () => {
    var d = this.metadata;
    const arr = [];
    let index = 0;
    for (var p in d) {
      if (d[p].update) {
        arr.push(<DropdownItem key={index} onClick={this.select}>{p}</DropdownItem>);
        index++;
      }
    }
    return arr.map((row) => {
      return row
    })
  }

  render = () => {
    return (
      <div>
        <Modal isOpen={this.state.modal} close={this.hide} toggle={this.toggle} className={this.props.className} backdrop={false} style={{ backgroundColor: '#f1f1f1' }}>
          <ModalBody>
            <Form inline>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleButton}>
                  <DropdownToggle caret>
                    {this.state.attrDisplayName}
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.getAttributes()}
                  </DropdownMenu>
                </ButtonDropdown>
              </FormGroup>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="value" className="mr-sm-2">{this.strings['NewValue']}:</Label>
                <Input type="text" name="value" id="value" placeholder={this.state.attrValue}></Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onFormSubmit}>{this.strings['Send']}</Button>
            <Button color="secondary" onClick={this.toggle}>{this.strings['Close']}</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
