//import confirm from "reactstrap-confirm";

import { isWindows } from "react-device-detect";

export default class UserMessage {

    constructor(){
        this.message = null;
    }

    alert = (message) => {
        if (this.message === message) return;
        this.message = message;
        alert(message);
    };

    error = (message) => {
      if (this.message === message) return;
      this.message = message;
      alert(message);
  };

}