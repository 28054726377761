import { combineReducers } from 'redux'
import tab from './TabReducer'
import types from './TypesReducer'
import documents from './DocumentReducer'
import i18 from './I18Reducer'

const mmdokApp = combineReducers({
  tab,
  types,
  documents,
  i18
})

export default mmdokApp
