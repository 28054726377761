import { connect } from 'react-redux'
import Footer from '../presentation/Footer'

const mapStateToProps = (state) => {
    return {
      strings: state.i18.strings,
      message: state.documents.message,
      msgtype: state.documents.msgtype
    }
  }

const FooterView = connect(mapStateToProps)(Footer)

export default FooterView;