import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Login from './components/presentation/Login'
import LoginSsn from './components/presentation/LoginSsn'
import ContentPane from './components/container/ContentPane';
import { documentCount, getDocuments, getNoDocuments } from './actions/DocumentAction';
import { getCreateTypes, getSearchTypes } from './actions/TypesAction';
import Pusher from 'pusher-js';
import HttpClient from './utils/HttpClient';
import { FREJA_LOGINTYPE } from './constants';

class App extends Component {

  constructor(props) {
    super(props);
    this.n = 0;
    this.loginRef = React.createRef();
    this.httpClient = new HttpClient();   
    this.pusher = new Pusher("933cd70740de7646ba61", {cluster: "eu"});
    const queryParams = new URLSearchParams(window.location.search);
    this.loginType = queryParams.get("login");
    this.token = queryParams.get("token");
    /*
    this.pusher = localStorage.getItem('mmserver').includes("localhost")? 
    new Pusher("933cd70740de7646ba61", {
      cluster: "eu"
    }) : null;
    */
  }

  async componentDidMount() {
    if (this.token) {
      sessionStorage.setItem('refresh_token', this.token);
      window.history.pushState(null, null, window.location.pathname);
      await this.httpClient.refreshTokens();
    }
    const isLoggedIn = await this.isLoggedIn();
    if (isLoggedIn)
      this.loggedIn();
    else
      this.loginRef.current.toggle();
  }

  handleNotification = (i) => {
    console.log("No documents=" + i);
    this.props.dispatch(documentCount(i));
  }

  isLoggedIn = async () => {
    let b = false;
    const token = sessionStorage.getItem('access_token');
    if (token) {
      try {
        b = true;
      }
      catch (e) {
        console.log(e);
      }
    }
    return b;
  }

  subscribe = () => {
    if (this.pusher.channel(sessionStorage.getItem('company')) == null) {
      const channel = this.pusher.subscribe(sessionStorage.getItem('company'));
      channel.bind('create', data => {
        this.props.dispatch(getNoDocuments());
      });
      channel.bind('delete', data => {
        this.props.dispatch(getNoDocuments());
      });
      console.log("Subscription set up")
    }
    else {
      console.log("Already subscribed");
    }
  }


  loggedIn = () => {
    this.props.dispatch(getNoDocuments());
    this.props.dispatch(getSearchTypes());
    this.props.dispatch(getCreateTypes());
    this.props.dispatch(getDocuments('*'));
    this.subscribe();
  }

  render = () => {
    return (
      <Fragment>
        <main className="my-5 py-5">
          <ContentPane />
        </main>
        { 
           this.loginType !== FREJA_LOGINTYPE ? 
             <Login ref={this.loginRef} loginCallback={this.loggedIn} loginUser={this.login} /> :
             <LoginSsn ref={this.loginRef} loginCallback={this.loggedIn} loginUser={this.login} />
        }
      </Fragment>
    );
  }
}

const AppView = connect()(App)
export default AppView;
