import React, { useState } from 'react'
import { BrowserView } from 'react-device-detect';
import {
  Badge, ButtonDropdown, Container, DropdownMenu, DropdownItem, DropdownToggle, Row, Col, Form, Input, Navbar, Nav,
  NavLink, NavItem,
} from 'reactstrap';
import HttpClient from '../../utils/HttpClient';
import { SEARCH_TAB, WRITE_TAB, VIEW_TAB,EDITOR_TAB, NEW_DOCUMENT_ACTION} from '../../constants';

const LOGO = '/images/mmdok_vit_svart.png';
const CREATE_ICON='/images/createDocument.png';

const Header = ({ activeTab, metadata, documentCount, documents, 
                  changeTab, changeLang, exportAsDip, filter, strings }) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const handleKeyUp = (e) => {
    filter(e.target.value);
    console.log("filter:" + e.target.value);
  }

  const handleLogout = () => {
    new HttpClient().logout();
    window.location.reload();
  }

  const changeLanguage = (lng) => {
    console.log("new language:" + lng)
    changeLang(lng);
  }

  const exportDip = () => {
    const ids = [];
    for (const d of documents) {
      if (d.id && d.id.value)
        ids.push(d.id);
    }
    exportAsDip(ids);
  }

  const getLoggedInInfo = () => {
    const email = sessionStorage.getItem('email');
    if (email && email != 'undefined') {
      return (email);
    }
    else {
      return (null);
    }
  }

  return (
    <header>
      <Navbar fixed="top" color="light" light expand="xs" className="d-flex border-bottom border-gray bg-white" style={{ height: 80 }}>
        <Container>
          <Row noGutters className="position-relative w-100 align-items-center">

            <Col className="d-none d-lg-flex d-lg-flex justify-content-center">
              <NavItem className="d-flex align-items-center">
                  <NavLink className="font-weight-bold text-dark" href="/">
                    <img src="/images/tinylogowide.svg" alt="sv" style={{width:100,height:80}}/>
                  </NavLink>
                </NavItem>
            </Col>
            <Col className="d-flex d-lg-flex justify-content-start">
              <Nav className="mrx-auto" navbar tabs>

                <NavItem className="d-flex align-items-center">
                  <NavLink className={activeTab === SEARCH_TAB ? "font-weight-bold" : ""}
                    onClick={() => { changeTab(SEARCH_TAB); }}>{strings['Search']}</NavLink>
                </NavItem>

                <NavItem className="d-flex align-items-center">
                  <NavLink className={activeTab === WRITE_TAB ? "font-weight-bold" : ""}
                    onClick={() => { changeTab(WRITE_TAB, metadata, NEW_DOCUMENT_ACTION); }}>{strings['Upload']}</NavLink>
                </NavItem>

                <NavItem className="d-flex align-items-center">
                  <NavLink className={activeTab === VIEW_TAB ? "font-weight-bold" : ""}
                    onClick={() => { changeTab(VIEW_TAB, metadata); }}>{strings['View']}</NavLink>
                </NavItem>

                <NavItem className="d-flex align-items-center">
                  <NavLink className={activeTab === EDITOR_TAB ? "font-weight-bold" : ""}
                    onClick={() => { changeTab(EDITOR_TAB, metadata, NEW_DOCUMENT_ACTION); }}><img src="/images/createDocument.png" alt="sv" style={{width:30,height:30}}/></NavLink>
                </NavItem>
              </Nav>
            </Col>

            <Col className="d-none d-md-flex justify-content-xs-start justify-content-lg-center">
              <Badge color="success">{documentCount}</Badge>
            </Col>

            <Col className="d-none d-md-flex justify-content-xs-start justify-content-lg-center">
                {getLoggedInInfo()}
            </Col>

            <Col className="d-none d-flex justify-content-end">
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                <BrowserView>
                  {strings['Menu']}
                </BrowserView>
                </DropdownToggle>
                <DropdownMenu container="body">
                  <DropdownItem onClick={() => handleLogout()}>{strings['SignOut']} </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem header>{strings['Language']}</DropdownItem>
                  <DropdownItem onClick={() => changeLanguage("en")}><img src="/images/eng.png" alt="en" style={{width:20,height:20}}/></DropdownItem>
                  <DropdownItem onClick={() => changeLanguage("sv")}><img src="/images/swe.png" alt="sv" style={{width:20,height:20}}/></DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>

          </Row>
        </Container>
      </Navbar>
    </header>
  )
}

/*
                  <DropdownItem divider />
                  <DropdownItem header>Exportera sökresultat</DropdownItem>
                  <DropdownItem onClick={() => exportDip()}>Som DIP</DropdownItem> 
*/
/*

            <Col className="d-none d-md-flex justify-content-xs-start justify-content-lg-center">
              <Form inline>
                <Input type="text" className="mr-3" placeholder={strings['Filter']} onKeyUp={(v) => handleKeyUp(v)} />
              </Form>
            </Col>
*/
/*
<DropdownItem onClick={() => onShopChange(shopToMap)}>
  <i class="some-icon" />
  <h3>Some heading</h3>
  <p>Some sub heading</p>{' '}
</DropdownItem>
*/

export default Header