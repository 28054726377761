export * from './urls'
export * from './state'
export const PAGE_SIZE = 10; 
export const CONTENT_CHUNK_SIZE = 10000000;
export const SEARCH_TAB = 'search';
export const WRITE_TAB = 'write';
export const VIEW_TAB = 'view';
export const EDITOR_TAB ='editor';
export const NEW_DOCUMENT_ACTION = 'newdoc';
export const UPDATE_DOCUMENT_CONTENT = "update_document_content";
export const PROD_SERVER = 'https://v84wxfpyu8.execute-api.eu-north-1.amazonaws.com/prod';
export const DEV_SERVER = 'https://bgw2m9xrcl.execute-api.eu-north-1.amazonaws.com/dev';
export const PROD_LOGIN_SERVER = 'https://4gprt3hjeb.execute-api.eu-north-1.amazonaws.com/prod';
export const DEV_LOGIN_SERVER = 'https://hzfhh2moki.execute-api.eu-north-1.amazonaws.com/dev';
export const LOCAL_SERVER = 'http://localhost:3001';
export const LOCAL_LOGIN_SERVER = 'http://localhost:3008';
export const SERVER_HOSTS = [PROD_SERVER, DEV_SERVER, LOCAL_SERVER];
export const LOGIN_SERVER_HOSTS = [PROD_LOGIN_SERVER, DEV_LOGIN_SERVER, LOCAL_LOGIN_SERVER];
export const BANKID_LOGINTYPE = 'bankid';
export const FREJA_LOGINTYPE = 'freja';
export const USERPASSWORD_LOGINTYPE = 'userpwd';
export const DEFAULT_LOGIN_TYPE=USERPASSWORD_LOGINTYPE;