import React from 'react';
import {
  Alert, Button, Col, Form, FormGroup, FormText,
  Label, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { SERVER_HOSTS, LOGIN_SERVER_HOSTS, PROD_SERVER, PROD_LOGIN_SERVER } from '../../constants';
import getStrings from "../../utils/Translations";
import HttpClient from '../../utils/HttpClient';
import UserMessage from '../../utils/UserMessage';

class LoginSsn extends React.Component {

  constructor(props) {
    super(props);
    this.loginCallback = this.props.loginCallback;
    this.loginUser = this.props.loginUser;
    this.httpClient = new HttpClient();
    this.userMessage = new UserMessage();
    this.strings = getStrings();
    let server = PROD_SERVER;
    let loginserver = PROD_LOGIN_SERVER;
    if (this.isDev()){
      server = localStorage.getItem('mmserver');
      if (!server) server = SERVER_HOSTS[0];
      loginserver = localStorage.getItem('mmloginserver');
      if (!loginserver) loginserver = LOGIN_SERVER_HOSTS[0];
    }
    this.state = {
      isLogin: true,
      email: '',
      modal: false,
      nestedModal: false,
      nestedCode: '',
      server: server,
      isLoginFailed: false,
      isCompany: false,
      validate: {
         emailState: '',
      },
    }
    this.resetPasswordModal = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      isLoginFailed: false
    });
    this.strings = getStrings();
  }

  toggleNested = () => {
    this.setState({
      nestedModal: !this.state.nestedModal
    });
  }

  hide = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  hideNested = () => {
    this.setState({
      nestedModal: !this.state.nestedModal
    });
  }

  ping = () => {
    new HttpClient().ping();
  }

  validateEmail(e) {
    const emailRex = /^.+@.+$/;
    const { validate } = this.state
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success'
    } else {
      validate.emailState = 'has-danger'
    }
    this.setState({ validate })
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  }

  async submitForm(e) {
    e.preventDefault();
    console.log(`Email: ${this.state.email}`)
    console.log(this.state.isLogin)
    this.loginAws();
  }


  loginAws = async () => {
    try {
      await this.httpClient.check(this.state.email, "freja");
      this.userMessage.alert(this.strings["OpenBankid"]);
      await this.httpClient.login_email(this.state.email);
      this.loginCallback();
      this.hide();
    }
    catch (error) {
      if (error.response && error.response.status && error.response.status === 401) {
        this.userMessage.error(this.strings["WrongLogin"]);
      }
      else if (error.response && error.response.status && error.response.status === 429) {
        this.userMessage.error(this.strings["TooManyLogins"]);
      }
      else {
        console.error("Failed to login: " + JSON.stringify(error));
        this.userMessage.error(this.strings["LoginFailed"]);
      }
    }
  }

  warning = () => {
    if (this.state.isLoginFailed) {
      return (
        <div style={{ paddingTop: 20 }}>
          <Alert color="danger">
            {this.strings["LoginFailed"]}
          </Alert>
        </div>
      );
    }
  }

  handleKeyUp() {
    if (this.state.isLoginFailed)
      this.setState({ isLoginFailed: false })
  }

  onServerChange = e => {
    this.setState({ server: e.target.value });
    localStorage.setItem('mmserver', e.target.value);
  }

  onLoginServerChange = e => {
    this.setState({ server: e.target.value });
    localStorage.setItem('mmloginserver', e.target.value);
  }

  onRegister = () => {
    this.setState({ isLogin: false });
  }

  onLogin = () => {
    this.setState({ isLogin: true });
  }

  getCompanySelection = () => {
    if (!this.state.isLogin)
      return (
        <Col>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" name="isCompany" id="isCompany" value={this.state.isCompany} onChange={(e) => this.handleChange(e)}/>{' '}
              {this.strings["Company"]}
            </Label>
          </FormGroup>
        </Col>
      )
  }

  getTitle() {
    if (this.state.isLogin) {
      return this.strings["SignIn"];
    }
    else {
      return this.strings["Register"]
    }
  }

  getButtonTitle() {
    if (this.state.isLogin) {
      return this.strings["SignIn"];
    }
    else {
      return this.strings["Register"];
    }
  }

  isDev() {
    return window.location.href.includes("localhost") || window.location.hostname.startsWith("dev")
  }

  getServerList() {
    if (this.isDev())
      return (
        <Col>
          <FormGroup>
            <Label for="serverSelect">{this.strings["Server"]}</Label>
            <Input type="select" name="select" id="serverSelect"
              value={this.state.server} onChange={this.onServerChange}>
              {SERVER_HOSTS.map(host => <option>{host}</option>)}
            </Input>
            <Label for="serverSelect">"Login" {this.strings["Server"]}</Label>
            <Input type="select" name="select" id="loginServerSelect"
              value={this.state.loginserver} onChange={this.onServerChange}>
              {LOGIN_SERVER_HOSTS.map(host => <option>{host}</option>)}
            </Input>
          </FormGroup>
        </Col>
      )
    else {
      return (null);
    }
  }

  getLink = () => {
    const url = window.location.href.split('?')[0]
      return (
      <div>
        <a href={url}>Logga in med användarnamn och lösenord</a>
      </div>
    );
  }

  nestedModal = () => {
    if (this.state.isNestedPhaseEmail)
      return (
        <div>
          <ModalHeader>{this.strings["YourEmail"]}</ModalHeader>
        </div>
      )
  }

  render() {
    const { email } = this.state;
    return (
      <div className="textCenter">
        <Modal isOpen={this.state.modal} close={this.hide} toggle={this.toggle} backdrop={false}>
          <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}><Button color="success" type="submit" form="loginform">{this.getButtonTitle()}</Button></ModalHeader>
          <ModalBody>
            <Form id="loginform" className="form" onSubmit={(e) => this.submitForm(e)}>
              <Col>
                <FormGroup>
                  <Label>E-post</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="me@foo.bar"
                    value={email}
                    valid={this.state.validate.emailState === 'has-success'}
                    invalid={this.state.validate.emailState === 'has-danger'}
                    onChange={(e) => {
                      this.validateEmail(e)
                      this.handleChange(e)
                    }}
                  />
                  {/*
                  <FormFeedback valid>
                    {this.strings["LooksGood"]}
                  </FormFeedback>
                  <FormFeedback>
                    {this.strings["NotAValidEmail"]}
                  </FormFeedback>
                  */}
                  <FormText>{this.strings["UserNameShouldBeEmail"]}</FormText>
                </FormGroup>
              </Col>


            </Form>
            {this.warning()}
            <Modal isOpen={this.state.nestedModal} toggle={this.toggleNested} backdrop={false}>
              {this.nestedModal()}
            </Modal>
          </ModalBody>

          <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
            {this.getLink()}
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

export default LoginSsn;