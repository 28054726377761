import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import ReactPlayer from 'react-player'
import React, { useState, Fragment } from 'react';
import Center from 'react-center';
import { Badge, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';

const ContentViewer2 = ({ url, documents, metadata, index, strings, updateUrl }) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const width = useWindowWidth();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    
    const getViewer = () => {
        const mimeType = metadata['Mimetype'].value;
        if ( mimeType === "application/pdf" || url.indexOf(".pdf") !== -1) {
            return (
                     <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                       <Page pageNumber={pageNumber} width={Math.min(width * 0.9, 400)} />
                     </Document>
                  )
        }
        else if (mimeType.indexOf("video/") != -1) {
           return(<ReactPlayer controls playsinline playing url={url} />)
        }
        else if (mimeType.indexOf("image/") != -1) {
            return(<img src={url} style={{ maxWidth: "100%" }}/>)
         }
         else if (mimeType.indexOf("text/csv") != -1 || mimeType.indexOf("text/html") != -1) {
            return(<embed type="text/html" src={url} width="100%" height="600" scrolling="yes"></embed>)
         }
         else if (mimeType.indexOf("text/") != -1) {
            return(<iframe src={url} style={{ width: "100%", scrolling:"yes"}}></iframe>)
         }
         else {
            return (<p><b>{mimeType + " " + strings.NoRender}</b></p>);
        }
    }

    const setPage = step => {
        let n = pageNumber + step;
        if (n > numPages) n = numPages;
        else if (n < 1) n = 1;
        console.log("Setting pageNumber to:" + n + " of " + numPages);
        setPageNumber(n);
    }

    const getPageSelector = () => {
        if (numPages > 1)
            return (
                <Center>
                    <Button style={{ margin: 10 }} className="mr-1" onClick={() => { setPage(-1); }} color="info">&lt;</Button>{' '}
                    <Badge style={{ marginLeft: 9, marginRight: 5 }} color="dark">{pageNumber}</Badge>{' '}
                    <Button style={{ margin: 10 }} className="mr-3" onClick={() => { setPage(+1); }} color="info">&gt;</Button>{' '}
                </Center>
            )
        else return (null);
    }
    if (documents && documents.length > 0)
        return (
            <Fragment>
                {getPageSelector()}
                <Center>
                {getViewer()}
                </Center>
            </Fragment >
        )
    else return (
        <Card>
            <CardBody>
                <CardTitle tag="h4">{strings.ViewTitle}</CardTitle>
                <CardText>
                    {strings.ViewText1}:
                    <ol>
                        <li>{strings.ViewList1}</li>
                        <li>{strings.ViewList2}</li>
                        <li>{strings.ViewList3}</li>
                        <li>{strings.ViewList4}</li>
                    </ol>
                    {strings.ViewText2}
                </CardText>
            </CardBody>
        </Card>
    )
}
export default ContentViewer2;

