import React from 'react';
import {
  Alert, Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
  FormGroup, Form, FormText, Label, Input, Modal, ModalHeader, ModalBody,
  ModalFooter
} from 'reactstrap';
import {
  LOCAL_LOGIN_SERVER, SERVER_HOSTS, LOGIN_SERVER_HOSTS, PROD_SERVER,
  DEV_LOGIN_SERVER, PROD_LOGIN_SERVER
} from '../../constants';
import HttpClient from '../../utils/HttpClient';
import getStrings from "../../utils/Translations";

const LoginPhase = {
  Initial: 0,
  MethodOK: 1,
  EmailOK: 2,
  PasswordOK: 3,
  CodeOK: 4
}

const ValidationState = {
  OK: 0,
  KO: 1
}

const LoginMethods = {
  None: 0,
  PasswordOnly: 1,
  PasswordWithGoogleAuthenticator: 2,
  PasswordWithEmailCode: 3,
  Freja: 4
}

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.loginCallback = this.props.loginCallback;
    this.loginUser = this.props.loginUser;
    this.httpClient = new HttpClient();
    this.strings = getStrings();
    let server = PROD_SERVER;
    let loginserver = PROD_LOGIN_SERVER;
    if (this.isDev()) {
      server = localStorage.getItem('mmserver');
      if (!server) server = SERVER_HOSTS[0];
      loginserver = localStorage.getItem('mmloginserver');
      if (!loginserver) loginserver = LOGIN_SERVER_HOSTS[0];
    }

    this.emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.passwordRex = /^.{4,}$/;
    this.codeRex = /^[0-9]{6}$/
    
    this.state = {
      phase: LoginPhase.Initial,
      dropdownOpen: false,
      email: '',
      password: '',
      code: '',
      loginMethod: LoginMethods.None,
      loginMethodName: '',
      messageColor: 'red',
      messageContent: '',
      server: server,
      isLoginFailed: false,
      validate: {
        emailState: ValidationState.KO,
        passwordState: ValidationState.KO,
        codeState: ValidationState.KO
      },
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      isLoginFailed: false
    });
    this.strings = getStrings();
  }

  hide = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  ping = () => {
    new HttpClient().ping();
  }

  handleEmailChange(e) {
    const { validate } = this.state;
    if (this.emailRex.test(e.target.value)) {
      validate.emailState = ValidationState.OK
    } else {
      validate.emailState = ValidationState.KO
    }
    this.setState({ validate, phase: LoginPhase.MethodOK, email: e.target.value, password: '', code: '' })
  }

  handlePasswordChange(e) {
    const { validate } = this.state;
    if (this.passwordRex.test(e.target.value)) {
      validate.passwordState = ValidationState.OK
    } else {
      validate.passwordState = ValidationState.KO
    }
    this.setState({ validate, phase: LoginPhase.EmailOK, password: e.target.value, code: '' })
  }

  handleCodeChange(e) {
    const { validate } = this.state;
    if (this.codeRex.test(e.target.value)) {
      validate.codeState = ValidationState.OK
    } else {
      validate.codeState = ValidationState.KO
    }
    this.setState({ validate, code: e.target.value })
  }

  login = async (event) => {
    try {
      await this.httpClient.login(this.state.email, this.state.password, this.state.code)
      this.loginCallback();
      this.hide();
    }
    catch (error) {
      if (error.code && error.code === "NotAuthorizedException") {
        console.log("Kunde ej logga in");
        alert(this.strings["WrongLogin"]);
      }
      else {
        console.error("Failed to login: " + JSON.stringify(error));
        alert(this.strings["LoginFailed"]);
      }
    }
  }

  checkPassword = async () => {
      const code = await this.httpClient.checkPwd(this.state.email, this.state.password);
      if (code == 200) return true;
      else {
        let msg = (code === 401)? "Fyll i ett korrekt lösenord" : "Misslyckades verifiera lösenord";
        alert(msg);
        return false;
      }
  }

  submitForm = async(e) => {
    e.preventDefault();
    console.log(`Email: ${this.state.email}`)
    await this.stateMachine();
  }

  async stateMachine() {
    if (this.state.phase == LoginPhase.Initial) {
      this.setState({ phase: LoginPhase.MethodOK })
    }
    else if (this.state.phase == LoginPhase.MethodOK) {
      if (this.state.validate.emailState == ValidationState.OK) {
        this.setState({ phase: LoginPhase.EmailOK})
      }
      else {
        alert("Var god fyll i en korrekt e-post adress");
      }
    }
    else if (this.state.phase == LoginPhase.EmailOK) {
      if (this.state.validate.passwordState == ValidationState.OK) {
        if (await this.checkPassword()) {
          this.setState({ phase: LoginPhase.PasswordOK }) 
          this.sendCode();
        }
        else {
          this.setState({ phase: LoginPhase.EmailOK })
        }
      }
      else {
        this.setState({ phase: LoginPhase.EmailOK }) 
        alert("Var god fyll i ett lösenord");
      }
    }
    else if (this.state.phase == LoginPhase.PasswordOK) {
      if (this.state.validate.codeState == ValidationState.OK) {
        this.setState({ phase: LoginPhase.CodeOK }) 
        await this.login();
      }
      else {
        alert("Var god fyll i en sexsiffrig kod");
      }
    }
    else if (this.state.phase == LoginPhase.CodeOK) {
      await this.login();
    }
  }

  warning = () => {
    if (this.state.isLoginFailed) {
      return (
        <div style={{ paddingTop: 20 }}>
          <Alert color="danger">
            {this.strings["LoginFailed"]}
          </Alert>
        </div>
      );
    }
  }

  onServerChange = e => {
    this.setState({ server: e.target.value });
    localStorage.setItem('mmserver', e.target.value);
  }

  onLoginServerChange = e => {
    this.setState({ loginserver: e.target.value });
    localStorage.setItem('mmloginserver', e.target.value);
  }

  onRegister = () => {
    this.httpClient.register();
  }

  getLoginServerUrl = () => {
    /*
    let loginserver = PROD_LOGIN_SERVER;
    if (window.location.href.includes('localhost'))
    loginserver = localStorage.getItem('mmloginserver');
    else if (window.location.href.includes('dev'))
    loginserver = DEV_LOGIN_SERVER;
    return PROD_LOGIN_SERVER//loginserver;
    */
    return PROD_LOGIN_SERVER; //DEV_LOGIN_SERVER; //LOCAL_LOGIN_SERVER
  }

  onLogin = () => {
    this.setState({ isLogin: true });
  }

  sendCode = async () => {
    if (!this.emailRex.test(this.state.email))
      this.setState({
        messageContent: 'Du måste ange en korrekt e-postadress',
        messageColor: 'red'
      });
    else {
      try {
        await this.httpClient.sendCode(this.state.email);
        this.setState({
          messageContent: 'Fyll i den kod som är skickad till: ' + this.state.email,
          messageColor: 'green'
        });
      }
      catch (error) {
        this.setState({
          messageContent: 'Misslyckades att skicka e-post',
          messageColor: 'red'
        });
      }
    }
  }

  getCompanySelection = () => {
    /*
    if (!this.state.isLogin)
    return (
    <Col>
    <FormGroup check>
    <Label check>
    <Input type="checkbox" name="isCompany" id="isCompany" value={this.state.isCompany} onChange={(e) => this.handleChange(e)}/>{' '}
    {this.strings["Company"]}
    </Label>
    </FormGroup>
    </Col>
    )
    */
    return (null);
  }


  isDev = () => {
    return window.location.href.includes("localhost") || window.location.hostname.startsWith("dev")
  }

  getServerList = () => {
    if (false && this.isDev())
      return (
        <Col>
          <FormGroup>
            <Label for="serverSelect">{this.strings["Server"]}</Label>
            <Input type="select" name="select" id="serverSelect"
              value={this.state.server} onChange={this.onServerChange}>
              {SERVER_HOSTS.map(host => <option>{host}</option>)}
            </Input>
            <Label for="serverSelect">Login {this.strings["Server"]}</Label>
            <Input type="select" name="select" id="loginServerSelect"
              value={this.state.loginserver} onChange={this.onLoginServerChange}>
              {LOGIN_SERVER_HOSTS.map(host => <option>{host}</option>)}
            </Input>
          </FormGroup>
        </Col>
      )
    else {
      return (null);
    }
  }

  fieldOne = () => {
    return (
      <Col>
        <FormGroup>
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
            <DropdownToggle caret> {this.getLoginMethodText()}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.handleMethodChange(LoginMethods.PasswordOnly)}>
                Med Email
              </DropdownItem>
              <DropdownItem onClick={() => this.handleMethodChange(LoginMethods.Freja)}>
                Med Freja
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <FormText>
              {this.getChoiceHelpText()}
            </FormText>
        </FormGroup>
      </Col>
    )
  }

  fieldTwo = () => {
    if (this.state.loginMethod != LoginMethods.None && 
        this.state.loginMethod != LoginMethods.Freja) {
      return (
        <Col>
          <FormGroup>
            <Label>E-postadress</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder=""
              size="100"
              value={this.state.email}
              valid={this.state.validate.emailState == ValidationState.OK}
              invalid={this.state.validate.emailState == ValidationState.KO}
              onChange={(e) => {
                this.handleEmailChange(e)
                //this.handleChange(e)
              }}
            />
          </FormGroup>
        </Col>
      );
    }
    else return (null);
  }
  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleMethodChange = (method) => {
    if (method == LoginMethods.Freja) {
      this.setState({
        loginMethod: method,
        loginMethodName: "Med Freja",
        phase: LoginPhase.MethodOK
      })
      window.location.href = this.getLoginServerUrl() + "/login?type=freja";
    }
    else {
      let name = "Välj inloggningsmetod";
      if (method == LoginMethods.PasswordOnly) {
        name = "Med E-post";
      }
      this.setState({
        loginMethod: method,
        loginMethodName: name,
        phase: LoginPhase.MethodOK
      })
      this.stateMachine();
    } 
  }

  getLoginMethodText = () => {
    if (this.state.loginMethod != '') {
      return (this.state.loginMethodName);
    }
    else {
      return ("Välj inloggninsmethod");
    }
  }

  getLinks = () => {
    if (this.state.phase <= LoginPhase.EmailOK) {
      return ( 
        <>
        <FormText key={0}><a href={this.getLoginServerUrl()+"/register"}>Glömt lösenord</a></FormText>
        <FormText key={1}><a href={this.getLoginServerUrl()+"/register?newuser=true"}>Registrera ny fristående användare</a></FormText>
        </>
      ) 
    }
    else {
      return (null);
    }
  }

  fieldThree = () => {
    if (this.state.phase >= LoginPhase.MethodOK && 
        this.state.loginMethod != LoginMethods.Freja) {
      return (
        <Col>
          <FormGroup>
            <Label for="thePassword">{this.strings["Password"]}</Label>
            <Input
              type="password"
              name="password"
              id="thePassword"
              placeholder=""
              value={this.state.password}
              valid={this.state.validate.passwordState == ValidationState.OK}
              invalid={this.state.validate.passwordState == ValidationState.KO}
              onChange={(e) => {
                this.handlePasswordChange(e)
              }}
            />
            {this.getLinks()}
          </FormGroup>
        </Col>
      )
    }
    else {
      return (null)
    }
  }

  getChoiceHelpText = () => {
    let text = "";
    if (this.state.loginMethod == LoginMethods.Freja) {
      text = "Var god vänta på Freja"
    }
    return text;
  }

  getCodeHelpText = () => {
    let text = "";
    if (this.state.loginMethod == LoginMethods.PasswordWithEmailCode) {
      text = "Fyll i den kod som skickats till din e-post: " + this.state.email;
    }
    return text;
  }

  fieldFour = () => {
    if (this.state.phase >= LoginPhase.PasswordOK &&
        this.state.loginMethod != LoginMethods.Freja) {
      return (
        <Col>
          <FormGroup>
            <Label>Kod</Label>
            <Input
              type="text"
              name="code"
              id="code"
              placeholder=""
              value={this.state.code}
              valid={this.state.validate.codeState == ValidationState.OK}
              invalid={this.state.validate.codeState == ValidationState.KO}
              onChange={(e) => {
                this.handleCodeChange(e)
              }}
            />
            <FormText>
              {this.getCodeHelpText()}
            </FormText>
          </FormGroup>
          <p style={{ color: this.state.messageColor }}>{this.state.messageContent}</p>
        </Col>
      )
    }
    else {
      return (null)
    }
  }

  buttonText = () => {
    return this.state.phase >= LoginPhase.PasswordOK? "Logga in" : "Fortsätt";
  }

  buttonColor = () => {
    return this.state.phase >= LoginPhase.PasswordOK? "success" : "primary";
  }

  getFooter = () => {
    if (this.state.phase > LoginPhase.Initial) {
      return (
        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <Button color={this.buttonColor()} type="submit" form="loginform">{this.buttonText()}
          </Button>
        </ModalFooter>
      )
    }
    else {
      return (null)
    }
  } 


  render = () => {
    return (
      <div className="textCenter">
        <Modal isOpen={this.state.modal} close={this.hide} toggle={this.toggle} backdrop={false}>
          <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>Inloggning</ModalHeader>
          <ModalBody style={{ display: 'flex', justifyContent: 'center' }}>
            <Form id="loginform" className="form" onSubmit={(e) => this.submitForm(e)}>
              {this.fieldOne()}
              {this.fieldTwo()}
              {this.fieldThree()}
              {this.fieldFour()}
            </Form>
            {this.warning()}
          </ModalBody>
          {this.getFooter()}
        </Modal>

      </div>
    );
  }
}

/*
<Col>
<FormGroup>
<Label for="thePassword">{this.strings["Password"]}</Label>
<Input
type="password"
name="password"
id="thePassword"
placeholder="********"
value={password}
onChange={(e) => this.handleChange(e)}
onKeyUp={(v) => this.handleKeyUp(v)}
/>
</FormGroup>
</Col>
<Col>
<FormGroup>
<Label>Code</Label>
<Input
type="text"
name="code"
id="code"
placeholder="123456"
value={code}
onChange={(e) => {
this.handleChange(e)
}}
/>
<FormText>
Fyll nedan i en kod från Google Authenticator eller skicka koden via e-post genom att trycka på denna länk:&nbsp;
<a onClick={this.onSendCode} href="#">e-post</a>
</FormText>
</FormGroup>
<p style={{ color: this.state.messageColor }}>{this.state.messageContent}</p>
</Col>
{this.getPasswordField2()}
{this.getServerList()}
{this.getCompanySelection()}
*/

export default Login;
