import ActionTypes from '../actions/Types';
import getStrings from '../utils/Translations'

const INITIAL_STATE = {
  strings: getStrings()
}

const i18 = (state = INITIAL_STATE, event) => {
  let newState = { ...state };
  switch (event.type) {
    case ActionTypes.CHANGE_LANG:
      localStorage.setItem('lang', event.lang);
      newState.strings = getStrings();
      return newState;        
    default:
      return state
  }
}

export default i18;
