import { connect } from 'react-redux'
import { changeUpdateType } from '../../actions/TypesAction'
import MetadataForm from '../presentation/MetadataForm'
import { VIEW_TAB, WRITE_TAB, NEW_DOCUMENT_ACTION, UPDATE_DOCUMENT_CONTENT,EDITOR_TAB } from '../../constants';

const mapStateToProps = (state) => {
  const t = state.types;
  let metadata = null;

 /*
  if (( state.tab.activeTab !== WRITE_TAB || state.tab.activeTab !== EDITOR_TAB || state.tab.action !== NEW_DOCUMENT_ACTION) && 
      (state.documents !== null || state.documents.metadata !== null) || Object.keys(state.documents.metadata).length !== 0) {
    metadata = state.documents.metadata;
    alert(Object.keys(state.documents.metadata).length)
  }
  */

  if (state.tab.action !== NEW_DOCUMENT_ACTION && state.documents !== null && state.documents.metadata !== null) 
    metadata = state.documents.metadata;

  let documentTypes = null;
  let fields = [];
  let types = [];
  let form = [];
  let selected = "";
  if (state.tab.activeTab === VIEW_TAB || state.tab.action === UPDATE_DOCUMENT_CONTENT) {
    if (t.searchForm) {
      if (t.searchForm.documentTypes) documentTypes = t.searchForm.documentTypes;
      if (t.searchForm.types) types = t.searchForm.types;
      if (t.searchForm.fields) fields = t.searchForm.fields;
      if (t.searchForm.selected) selected = t.searchForm.selected;
      if (t.searchForm.form) form = t.searchForm.form;
    }
  }
  else if (state.tab.activeTab === WRITE_TAB) {
    if (t.uploadForm) {
      if (t.uploadForm.documentTypes) documentTypes = t.uploadForm.documentTypes;
      if (t.uploadForm.types) types = t.uploadForm.types;
      if (t.uploadForm.fields) fields = t.uploadForm.fields;
      if (t.uploadForm.selected) selected = t.uploadForm.selected;
      if (t.uploadForm.form) form = t.uploadForm.form;
   }
  }
  else if (state.tab.activeTab === EDITOR_TAB) {
    if (t.uploadForm) {
      if (t.uploadForm.documentTypes) documentTypes = t.uploadForm.documentTypes;
      if (t.uploadForm.types) types = t.uploadForm.types;
      if (t.uploadForm.fields) fields = t.uploadForm.fields;
      if (t.uploadForm.selected) selected = t.uploadForm.selected;
      if (t.uploadForm.form) form = t.uploadForm.form;
   }
  }

  return {
    documentTypes: documentTypes,
    types: types,
    fields: fields,
    selected: selected,
    form: form,
    initialMetadata: metadata,
    strings: state.i18.strings
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeDocumentType: docType => {
      dispatch(changeUpdateType(docType))
    }
  }
}

const MetadataFormView = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MetadataForm)

export default MetadataFormView;