import { connect } from 'react-redux'
import Documentlist from '../presentation/Documentlist'
import { changeTab } from '../../actions/TabAction'
import { sort, updateDocument, deleteDocument, setCurrentDocument, editDocument, showErrorMessage} from '../../actions/DocumentAction'
import { EDITOR_TAB, VIEW_TAB, WRITE_TAB, UPDATE_DOCUMENT_CONTENT } from '../../constants';

const mapStateToProps = (state) => {
  let o = {};
  if (state.documents.visiblelist != null)
    o = { documents: state.documents.visiblelist };
  o.strings = state.i18.strings;
  return o;
}

const mapDispatchToProps = dispatch => {
  return {
    showDocument: async (metadata, version) => {
      const r = await dispatch(setCurrentDocument(metadata, version));
      if (r.error) 
        dispatch(showErrorMessage(r.error));
      else 
        dispatch(changeTab(VIEW_TAB, metadata));
    },
    sort: (column) => {
      dispatch(sort(column));
    },
    update: (metadata) => {
      dispatch(updateDocument(metadata));
    },
    save: (metadata) => {
      dispatch(changeTab(WRITE_TAB, metadata, UPDATE_DOCUMENT_CONTENT));
    },
    remove: (metadata) => {
      dispatch(deleteDocument(metadata));
    },
    editDocument: async (metadata) => {
      await dispatch(setCurrentDocument(metadata));
      await dispatch(changeTab(EDITOR_TAB, metadata));
    }
  }
}

const DocumentlistView = connect(mapStateToProps, mapDispatchToProps)(Documentlist)

export default DocumentlistView;