import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Field from '../../utils/Field';

export default class MetadataForm extends React.Component {

  constructor(props) {
    super(props);
    this.field = new Field(this.handleKeyUp);
    this.fields = props.fields;
    this.initialMetadata = props.initialMetadata;
    this.isFormLocked = this.initialMetadata != null;
    this.docType = props.selected;
    this.strings = props.strings;
    if (this.docType != "" && props.types.length > 0) this.docType = props.types[0].props.children; //FIX THIS!
  }

  handleChange(v) {
    this.docType = v;
    this.props.changeDocumentType(v);
  }

  getValueById = (id) => {
    const e = document.getElementById(id);
    return e && e.value? e.value : null;;
  }

  getMetadata = () => {
    if (this.docType == null) {
      alert("Ingen dokumenttyp vald!");
      return null;
    }
    let json = '{"type":"' + this.props.selected + '"';
    for (var i = 0; i < this.props.fields.length; i++) {
      const field = this.props.fields[i];
      let value = this.getValueById(field['name']);
      const datatype = field['type'];
      if (datatype == 'boolean') 
        value = (value === "on")
      if (value && value.length > 0){
        if (datatype == 'number' || datatype == 'boolean')
          json += ',"' + field['name'] + '":' + value;
        else
          json += ',"' + field['name'] + '":"' + value + '"';
      }
    }
    json += '}';
    console.log(json)
    return json;
  }

  getFieldsForLockedForm = () => {
    const a = [];
    for (const key in this.initialMetadata) {
      const val = this.initialMetadata[key];
      if (val && 'name' in val) {
        a.push(val);
      }
    }
    a.push({'displayname':'Creator','displayname_sv':'Skapare'});
    a.push({'displayname':'Created at','displayname_sv':'Skapat'});
    a.push({'displayname':'Version','displayname_sv':'Version'});
    a.push({'displayname':'File name','displayname_sv':'Filnamn'});
    a.push({'displayname':'Mimetype','displayname_sv':'Mimetype'});
    return a;
  }

  getDocId = () => {
    return (this.initialMetadata && 'id' in this.initialMetadata)? this.initialMetadata['id'].value : null;
  }

  getForm = () => {
    const fields = this.isFormLocked? this.getFieldsForLockedForm() : this.props.fields;
    return fields.map((item) => this.field.getForm(item, false, this.props.initialMetadata, this.isFormLocked));
  }

  getHeader = () => {
    if (this.isFormLocked) return this.getLockedHeader();
    else return this.getOpenHeader();
  }


  getOpenHeader = () => {
    return (
      <FormGroup>
        <h4>{this.props.strings['MetadataForTheDocuments']}</h4>
        <hr style={{
          color: 'gray',
          backgroundColor: 'gray',
          height: .5,
          borderColor: 'gray'
        }} />
        <Label for="documentTypes">{this.props.strings['Documenttypes']}</Label>
        <Input type="select" 
               name="select" 
               id="documentTypes" 
               value={this.props.selected}
               onChange={(e) => { this.handleChange(e.target.value) }}>
          {this.props.types}
        </Input>
      </FormGroup>
    )
  }

  getLockedHeader = () => {
    const typeAttr = this.props.strings['Type'];
    const value = (typeAttr in this.initialMetadata && 'value' in this.initialMetadata[typeAttr])? 
       this.initialMetadata[typeAttr].value : "";
    return (
      <FormGroup key="1">
          <Label>{this.props.strings['Documenttype']}</Label>
          <Input type="text"
              id="dokumenttyp"
              name="Dokumenttyp"
              value={value}
          />
      </FormGroup>
  );
  }

  handleKeyUp = (e) => {
    const value = e.target.value;
    const format = e.target.getAttribute('format');
    if (value.match(format))
      e.target.setAttribute('class', 'is-valid form-control');
    else
      e.target.setAttribute('class', 'is-invalid form-control');
  }

  handleFieldChange = (v) => {
    console.log("HandleFieldChange");
  }

  render() {
    return (
      <Form>
        {this.getHeader()}
        {this.getForm()}
      </Form>
    );
  }
}