import ActionTypes from './Types';
import HttpClient from '../utils/HttpClient';

export function getNoDocuments() {
  return dispatch => new HttpClient().count()
    .then(
      data => dispatch({ type: ActionTypes.DOCUMENT_COUNT, data: data.count }),
      err => () => { console.log("Could not get number of documents"); }
    )
}

export function documentCount(count) {
  return dispatch => dispatch({ type: ActionTypes.DOCUMENT_COUNT, data: count })
}

export function docError (error) {
  return dispatch({ type: ActionTypes.GET_DOCUMENT_URL_FAILURE, error: r.error });
}

export function setCurrentDocument(metadata, version=0) {
  return dispatch => new HttpClient().download(metadata['id'].value, version)
    .then(
      url => dispatch({ type: ActionTypes.DOCUMENT_URL, url: url, metadata: metadata}),
      err => dispatch({ type: ActionTypes.GET_DOCUMENT_URL_FAILURE, error: err })
    )
}

export const filter = (txt) => ({
  type: ActionTypes.FILTER_DOCUMENT_LIST,
  data: txt
})

export const sort = (column) => ({
  type: ActionTypes.SORT_DOCUMENTS,
  data: column
})

export const updateDocument = (metadata) => ({
  type: ActionTypes.UPDATE_DOCUMENT,
  data: metadata
})

export const deleteDocument = (metadata) => ({
  type: ActionTypes.DELETE_DOCUMENT,
  data: metadata
})

export const showErrorMessage = (message) => ({
  type: ActionTypes.SHOW_ERROR_MESSAGE,
  data: message
});

export const getDocuments = (docType, attributes) => {
  return dispatch => new HttpClient().list(docType, attributes)
    .then(
      data => { console.log("Got response!! " + data); return dispatch({ type: 'LOAD_DOCUMENTS_SUCCESS', data }) },
      err => { dispatch({ type: 'LOAD_DOCUMENTS_FAILURE', 
                data: err.response && err.response.data && err.response.data.message? err.response.data.message : "Failure to load documents" }) }
    )
  }

  export const exportDip = (ids) => {
    if (ids.length < 1) {
      return ({ 
                type: ActionTypes.EXPORT_DIP_FAILURE, 
                data: "Inget att exportera"
              })
    }
    else {
      return dispatch => new HttpClient().exportAsDip(ids)
        .then(
          data => { console.log("Response! " + JSON.stringify(data)); return dispatch({ type: ActionTypes.EXPORT_DIP_SUCCESS, data: "Export av dip klar" }) },
          err => { dispatch({ type: ActionTypes.EXPORT_DIP_FAILURE, 
                    data: err.response && err.response.data && err.response.data.message? err.response.data.message : "Failure to load documents" }) }
        )
    }
  }
  