import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';

export default class EditMetadataModal extends Component {

  constructor(props) {
    super(props);
    this.strings = this.props.strings;
    this.state = {
      modal: false,
      size: 'modal-lg'
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }

  hide = () => {
    this.setState({
      modal: false
    });
  }

  tdStyle = {
    fontSize: 14,
    wordWrap: 'break-word'
  };

  thStyle = {
    fontSize: 20
  }

  getAudits = () => {
    var audit = this.props.audit;
    if (audit != null && audit.value != null) {
      return audit.value.map((a, index) => {
        return (<tr key={index}><td style={this.tdStyle}>{a.ts.substr(0, 16).replace('T', ' ')}</td>
          <td style={this.tdStyle}>{a.user}</td><td style={this.tdStyle}>{a.op}</td></tr>
        )
      })
    }
  }

  getTable = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th style={this.thStyle}>{this.strings['Time']}</th>
            <th style={this.thStyle}>{this.strings['Name']}</th>
            <th style={this.thStyle}>{this.strings['Action']}</th>
          </tr>
        </thead>
        <tbody>
          {this.getAudits()}
        </tbody>
      </Table>
    );
  }

  render = () => {
    return (
      <div>
        <Modal isOpen={this.state.modal} close={this.hide} toggle={this.toggle} className={this.props.className} backdrop={false} style={{ backgroundColor: '#f1f1f1' }}>
          <ModalBody>
            {this.getTable()}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.hide}>{this.strings['Close']}</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
