import ActionTypes from '../actions/Types';
import { SEARCH_TAB, VIEW_ACTION } from '../constants';

const INITIAL_STATE = {
  activeTab: SEARCH_TAB,
  action: VIEW_ACTION
}

const tab = (state = INITIAL_STATE, event) => {
  let newState = { ...state };
  switch (event.type) {
    case ActionTypes.CHANGE_TAB:
      newState.activeTab = event.tabId;
      newState.action = event.action;
      return newState;        
    default:
      return state
  }
}

export default tab;