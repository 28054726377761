import React, { Component, Fragment } from 'react';
import { Button, ButtonGroup, Input, FormGroup, Progress, Table } from 'reactstrap';
import { BrowserView } from 'react-device-detect';
import DragAndDrop from './DragAndDrop';
import HttpClient from '../../utils/HttpClient';
import '../../styles/Filedrop.css';

const MAX_VISIBLE_COLUMNS = 4;
const getNumberOfColumns = () => {
  const nColumns = Math.round(window.innerWidth / 250);
  return (nColumns > MAX_VISIBLE_COLUMNS) ? MAX_VISIBLE_COLUMNS : nColumns;
}

export default class Filelist extends Component {

  state = {
    files: [],
    metadataFileObj: [],
    showFileBrowser: false,
    metafileExist: 0,
    noFilesToValidate: 0
  }

  constructor(props) {
    super(props);
    this.metadata = props.metadata;
    this.docid = props.docid;
    this.s3key = props.s3key;

    this.callback = (filelist) =>{
      //this.forceUpdate()
      this.setState({ files: filelist });
    }
  }

  importFile(e) {
    this.handleDrop(e.target.files);
  }

  /**
   * 
   * @param : changes in 2023-12-24
   * major change: if there is a file called "metadata.json", dont read metadata from UI 
   * upload all other files but NOT the file "metadata.json" itself, only read content of metadata.json and show it as read in UI
   * 
   * @returns 
   */
  handleDrop = (files) => {
    if (!files) return;
    let fileList = this.state.files;
    let metadataFileObj = this.state.metadataFileObj;
    let metafileExist = this.state.metafileExist;
    for (var i = 0; i < files.length; i++) {
      if (files[i].name === 'metadata.json') {
        this.setState({ metafileExist: this.state.metafileExist + 1 });
        metafileExist = 1;
      }

      if (!files[i].name) return
      files[i].sent = false;
      files[i].progress = 0;
      fileList.push(files[i]);
    }
    this.setState({ files: fileList });
    if (metafileExist === 1) {
      for (var m = 0; m < fileList.length; m++) {
        if (fileList[m].name === 'metadata.json') {
          var reader = new FileReader();
          reader.readAsText(fileList[m]);
          reader.onload = () => {
            let texten = reader.result.toString();
            const filtextAsJson = JSON.parse(texten);
            metadataFileObj.push(filtextAsJson);
            this.setState({ metadataFileObj: filtextAsJson });
          };
          break;
        }
      }
    }

  }

  sleep = time => {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  onSubmitFile = () => {
    const metadata = this.metadata();
    const docid = this.docid();
    const metadataFileObj = [...this.state.metadataFileObj];

    if (this.state.metafileExist === 0 && metadata == null) return;
    const fileList = [...this.state.files];

    if (docid && fileList.length > 1) {
      alert("Can only update with one document");
      return;
    }
    for (let i = 0; i < fileList.length; i++) {
      fileList[i].index = i;
      if (fileList[i].sent) console.log("Already sent: " + fileList[i].name);
      else {
        if (this.state.metafileExist === 1) {
          for (let s = 0; s < metadataFileObj.length; s++) {
            const filenameInside = JSON.stringify(metadataFileObj[s].filename);
            const realFilenamne = '"' + fileList[i].name + '"';
            if (realFilenamne === filenameInside && fileList[i].name !== 'metadata.json') {
              const egenmetadata = JSON.stringify(metadataFileObj[s].data);
              this.submitFileAws(i, fileList, egenmetadata, docid);
              break;
            }
          }
        } else {
          this.submitFileAws(i, fileList, metadata, docid);
        }
      }

    }
  }



  async submitFileAws(i, fileList, metadata, docid) {
    this.files = fileList;
    this.setState({ noFilesToValidate: this.state.noFilesToValidate + 1 });
    const httpClient = new HttpClient();

    httpClient.upload(JSON.parse(metadata), fileList[i], docid, fileList, this.callback)
      .then(result => {
        //fileList[i].sent = true;
        this.setState({ noFilesToValidate: this.state.noFilesToValidate - 1 });
      }).catch(error => {
        this.setState({ files: fileList, noFilesToValidate: this.state.noFilesToValidate - 1 });
        if (error.response && error.response.data && error.response.data.message) {
          console.log(error.response.data.message);
          this.setState({ files: fileList, noFilesToValidate: this.state.noFilesToValidate - 1 });
          this.props.showErrorMessage(error.response.data.message)
        }
        else {
          this.props.showErrorMessage("Misslyckades att skicka" + error);
        }
      });
  }

  base64 = (file) => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        //let encoded = reader.result.toString().replace('data:*/*;base64', '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
    });
  };


  onFileBrowse = (e) => {
    //this.setState({showFileBrowser: true});
    document.getElementById('fileInput').click();
  }

  onClearFiles = (e) => {
    this.setState({ files: [], isSent: false, metadataFileObj: [], metafileExist: 0 });
  }

  showFileType = () => {
    return window.innerWidth > 1000;
  }

  showMimeType = () => {
    return window.innerWidth > 500;
  }

  getInnerHeader = () => {
    const nColumns = getNumberOfColumns();
    if (nColumns < 3) {
      return (null);
    }
    else if (nColumns === 3) {
      return (<td>Mimetype</td>)
    }
    else {
      return (
        <Fragment>
          <td>Mimetype</td>
          <td>{this.props.strings['Size']}(MB)</td>
        </Fragment>
      )
    }
  }

  getInnerRow = file => {
    const nColumns = getNumberOfColumns();
    const filetype = file.type? file.type : "";
    if (nColumns < 3) {
      return (null);
    }
    else if (nColumns === 3) {
      return (<td>{filetype}</td>)
    }
    else {
      return (
        <Fragment>
          <td>{filetype}</td>
          <td>{Math.round(file.size / 1028)}</td>
        </Fragment>
      )
    }
  }

  rows = () => {
    return this.state.files.map((file, index) => {
      let sentColumn;
      if (file.sent) {
        sentColumn = <td style={{ color: 'green' }}>&#10004;</td>
      } else {
        //sentColumn = <td style={{ color: 'red' }}>&#10006;</td>
        sentColumn = <Progress className="my-3" value={file.progress} />
      }


      return (
        <tr key={index}>
          <td>{file.name}</td>
          {this.getInnerRow(file)}
          {sentColumn}
        </tr>
      )
    })
  }
  //<td style={{color:'green'}}>&#10004;</td>
  //

  extraSpace = () => {
    const row = <tr><td><br /></td><td><br /></td><td><br /></td><td><br /></td></tr>;
    switch (this.state.files.length) {
      case 0: return <Fragment>{row}{row}{row}</Fragment>
      case 1: return <Fragment>{row}{row}</Fragment>
      case 2: return <Fragment>{row}</Fragment>
      default: return <Fragment></Fragment>
    }
  }

  isDisabled() {
    return (this.state.noFilesToValidate > 0 || (this.state.files != null && this.state.files.length === 0))
  }

  render = () => {
    const styles = { textAlign: 'center', borderStyle: 'double', padding: '10px' };
    const styles2 = { display: 'none' };

    return (
      <Fragment>
        <DragAndDrop handleDrop={this.handleDrop} strings={this.props.strings}>
          <div style={styles}>
            <BrowserView>
              <h3>{this.props.strings['DropFilesHere']}</h3>
            </BrowserView>
            <FormGroup>
              <Input type="file" id="fileInput" style={styles2} multiple onChange={this.importFile.bind(this)} />
              <Button color="primary" onClick={this.onFileBrowse}>{this.props.strings['ChoseFile']}</Button>
            </FormGroup>
            <Table striped hover>
              <thead>
                <tr>
                  <td>{this.props.strings['Filename']}</td>
                  {this.getInnerHeader()}
                  <td>{this.props.strings['Sent']}</td></tr>
              </thead>
              <tbody>
                {this.rows()}
                {this.extraSpace()}
              </tbody>
            </Table>
            <ButtonGroup>
              <Button disabled={this.isDisabled()} color="success" onClick={this.onSubmitFile} style={{ margin: '10px', borderRadius: '5px' }}>{this.props.strings['Send']}</Button>
              <Button disabled={this.isDisabled()} color="danger" onClick={this.onClearFiles} style={{ margin: '10px', borderRadius: '5px' }}>{this.props.strings['Clear']}</Button>
            </ButtonGroup>
          </div>
        </DragAndDrop>
      </Fragment>
    );
  }
}


