import ActionTypes from '../actions/Types';
import _ from 'lodash';
import { BLANK_URL, PAGE_SIZE } from '../constants';
import getStrings from "../utils/Translations";

const INITIAL_STATE = {
  rawlist: [],  // documents from server
  filteredlist: [],  // filtered rawlist
  visiblelist: [],  // currently visible documents
  types: [], // document types
  sortOrder: {}, // sortorder for columns in document list , key=column name, value=true(ASC) or false(DESC)
  filter: '', //current filter for documents
  documentCount: 0,  //number of documents as reported from server
  currentPageNo: 0, //visible page number
  pageCount: 0,  //number of document pages
  url: BLANK_URL,  //document url to show
  metadata: {},
  message: '',
  msgtype: 1, //0=ok, 1=error
};

const documents = (state = INITIAL_STATE, action) => {
  let newState = _.cloneDeep(state);//{ ...state };
  newState.message = '';
  switch (action.type) {
    case ActionTypes.DOCUMENT_COUNT:
      newState.documentCount = action.data;
      return newState;
    case ActionTypes.DOCUMENT_URL:
        newState.url = action.url;
        newState.metadata = action.metadata;
        return newState;    
    case ActionTypes.CHANGE_TAB:
      if (action.metadata != null)
        newState.metadata = action.metadata;
      return newState;     
    case ActionTypes.LOAD_SEARCH_TYPES_SUCCESS:
      newState.types = getDocumentTypes(action.data);
      return newState;
    case ActionTypes.LOAD_DOCUMENTS_SUCCESS:
      newState.rawlist = selectFields(action.data, newState);
      reset(newState);
      return newState;
    case ActionTypes.LOAD_DOCUMENTS_FAILURE:    
      newState.message = action.data;
      newState.msgtype = 1;
      return newState;
    case ActionTypes.SHOW_ERROR_MESSAGE:    
      newState.message = action.data;
      newState.msgtype = 1;
      return newState;
    case ActionTypes.EXPORT_DIP_SUCCESS:
      newState.message = action.data? action.data : "Export av dip klar";
      newState.msgtype = 0;
      return newState;
    case ActionTypes.EXPORT_DIP_FAILURE:    
        newState.message = action.data? action.data : "Misslyckades att exportera dip";;
        newState.msgtype = 1;
        return newState;
    case ActionTypes.GET_DOCUMENT_URL_FAILURE:
        console.log(action.error);
        return state;
    case ActionTypes.CHANGE_PAGE:
      newState.currentPageNo = action.payload;
      newState.visiblelist = slice(newState.rawlist, newState.currentPageNo);
      return newState;
    case ActionTypes.FILTER_DOCUMENT_LIST:
      newState.filter = action.data;
      reset(newState);
      return newState;
    case ActionTypes.UPDATE_DOCUMENT:
      newState.rawlist[action.data._index.value] = action.data;
      rerender(newState);
      return newState;
    case ActionTypes.DELETE_DOCUMENT:
      newState.rawlist.splice(action.data._index.value, 1);
      rerender(newState);
      return newState;
    case ActionTypes.SORT_DOCUMENTS:
      const column = action.data;
      const sortOrder = getSortOrder(column, newState);
      newState.sortOrder[column] = sortOrder;
      newState.rawlist = sort(newState.rawlist, column, sortOrder);
      reset(newState);
      return newState;
    default:
      return state
  }
}

const reset = state => {
  state.currentPageNo = 0;
  state.filteredlist = filter(state.rawlist, state.filter);
  state.visiblelist = slice(state.filteredlist, state.currentPageNo);
  state.pageCount = Math.ceil(state.filteredlist.length / PAGE_SIZE);
}

const rerender = state => {
  state.filteredlist = filter(state.rawlist, state.filter);
  state.visiblelist = slice(state.filteredlist, state.currentPageNo);
  state.pageCount = Math.ceil(state.filteredlist.length / PAGE_SIZE);
}

const getDocumentTypes = (data) => {
  return data;
}

const assignValue = (e, value, type, keys, key) => {
  const attr = type.fields[key];
  if (key in type.fields) {
    const name = localStorage.getItem('lang') === 'sv'?
       attr.displayname_sv : attr.displayname;
    keys.add(type.fields[key].name);
    e[name] = { value: value};
    Object.assign(e[name], type.fields[key]);
  }
}

const selectFields = (data, state) => {
  const d = [];
  let currIndex = 0;
  for (let i = 0; i < data.length; i++) {
    let e = {};
    let o = data[i];
    if (o.hasOwnProperty('metadata') && typeof o.metadata === 'object') { 
      const type = state.types[o.type];
      if (type == null) {
        console.log("No valid type set!");
        continue;
      }
      const keys = new Set();
      for (const key in o.metadata) {      
         assignValue(e, o.metadata[key], type, keys, key);
      }  
      for (const key in type.fields) {
        if (!keys.has(key) && type.fields[key].type=="text") {
          assignValue(e, "", type, keys, key);
        }
      }
      const strings = getStrings();
      //if (fieldCount >= type.size) {
        e[strings['Type']] =  { value: o['type'] };
        e[strings['Creator']] =  { value: o['creator'] };
        e[strings['Created_at']] =  { value: o['ts'] };
        e['Version'] = ('version' in o) ? { value: o['version'] } : { value: 1 };
        const fileAttrs = o['file'];
        if (fileAttrs != null){
          e[strings['Filename']] =  { value: o['file']['name'] };
          e['Mimetype'] =  { value: o['file']['type'] };
        }
        e['id'] = { value: o['_id'] };
        e['_isEditable'] = { value: o.metadata.isEditable? o.metadata.isEditable : false };
        e['_index'] = { value: currIndex++ };
        e['_audit'] = { value: o['audit'] };

        d.push(e);
      //}
    }

  }
  return d;
}

const slice = (documents, currentPageNo) => documents.slice(currentPageNo * PAGE_SIZE, (currentPageNo + 1) * PAGE_SIZE)

const filter = (documents, filterText) => {
  const arr = documents.filter((row) => {
    for (var key in row) {
      const k = row[key]
      if (k != null && k.value != null && k.value.toString().includes(filterText)) return true;
    }
    return false;
  });
  let i;
  for (i=0; i<arr.length; i++) {
    arr[i]['_findex'] = {value: i};
  }
  return arr;
}

const getSortOrder = (column, state) => {
  let sortOrder = true;
  if (column in state.sortOrder)
    sortOrder = !state.sortOrder[column];
  return sortOrder;
}

const sort = (documents, column, order) => {
  let documentCopy = [...documents];
  documentCopy.sort(sortFunction);
  return documentCopy;

  function sortFunction(a, b) {
    a = a[column].value;
    b = b[column].value;
    let cmp = (a === b) ? 0 : (a < b) ? -1 : 1;
    return order ? cmp : cmp * -1;
  }
}

export default documents;
