export const ActionTypes = {
  CHANGE_TAB: "CHANGE_TAB"
};

export const Tabs = {
  SEARCH_TAB: 1,
  WRITE_TAB: 2,
  VIEW_TAB: 3,
  EDITOR_TAB: 4
}

export const changeTab = (tabId, metadata, action) => ({
  type: ActionTypes.CHANGE_TAB,
  tabId: tabId,
  metadata: metadata,
  action: action
})



