import { connect } from 'react-redux'
import { changeTab } from '../../actions/TabAction'
import { changeLang } from '../../actions/I18Action'
import { exportDip,filter } from '../../actions/DocumentAction'
import Header from '../presentation/Header'


const mapStateToProps = (state, ownProps) => {
  return {
    activeTab: state.tab.activeTab,
    documentCount: state.documents.documentCount,
    documents: state.documents.rawlist,
    strings: state.i18.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (tabId, metadata, action) => {
      dispatch(changeTab(tabId, metadata, action))
    },
    changeLang: (lang) => {
      dispatch(changeLang(lang))
    },
    exportAsDip: (ids) => {
      dispatch(exportDip(ids))
    },
    filter: (filterText) => {
      dispatch(filter(filterText))
    }
  }
}

const HeaderView = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderView;