import { connect } from 'react-redux'
import ContentViewer from '../presentation/ContentViewer'
import ContentViewer2 from '../presentation/ContentViewer2'
import { setCurrentDocument } from '../../actions/DocumentAction'
import { isBrowser } from 'react-device-detect';

const mapStateToProps = (state) => {
  let o = [];
  if (state.documents.url != null)
    o = { url: state.documents.url, metadata: state.documents.metadata, 
         documents: state.documents.filteredlist, index: state.documents.index};
  o.strings = state.i18.strings;
  return o;
}

const mapDispatchToProps = dispatch => {
  return {
    updateUrl: (metadata) => {
      dispatch(setCurrentDocument(metadata));
    }
  }
}

const ContentViewerView = isBrowser? 
  connect(mapStateToProps, mapDispatchToProps)(ContentViewer) : 
  connect(mapStateToProps, mapDispatchToProps)(ContentViewer2)

export default ContentViewerView;