import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AuditLogModal from './AuditLogModal';
import EditMetadataModal from './EditMetadataModal';
import HttpClient from '../../utils/HttpClient';
import _ from 'lodash';

export default class ActionMenu extends Component {

  httpClient = new HttpClient();

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.docid = this.props.metadata['id'].value;
    this.strings = this.props.strings;
    this.state = { dropdownOpen: false };
    this.modal = React.createRef();
    this.auditModal = React.createRef();
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  delete = async () => {
   const isOk = confirm(this.strings['ConfirmDelete'] + "?");
    if (isOk) {
      this.httpClient.delete(this.docid, () => {
        this.props.deleteDocument(this.props.metadata);
      });
    }
  }

  deleteVersion = async () => {
    const isOk = confirm(this.strings['ConfirmDeleteVersion'] + "?");
     if (isOk) {
       this.httpClient.delete(this.docid, () => {}, false);
     }
   }

  download_orginial = async () => {
    await this.download(false);
  }

  download_pdf = async () => {
    await this.download(true);
  }

  download = async (pdfFormat) => {
    try {
    const url = await new HttpClient().download(this.docid, 0, true, null, pdfFormat);
    let a = document.createElement('a');
    a.href = url;
    a.download = true;    
    a.click();
    }
    catch (e) {
      if (!e) e = "Could not fetch the file";
      alert(e);
    }
  }

  audit = () => {
    this.auditModal.current.toggle();
  }

  editDocument = () => {
    this.props.editDocument(this.props.metadata);
  }

  editMetadata = () => {
    this.modal.current.toggle();
  }

  save = () => {
    this.props.saveDocument(this.props.metadata, this.docid);
  }

  showVersion = () => {
    const meta = _.cloneDeep(this.props.metadata);
    meta.Version.value-=1;
    this.props.showVersion(meta, -1);
  }

  getEdit = () => {
    const meta = this.props.metadata;
    if (meta._isEditable && meta._isEditable.value && this.props.metadata._isEditable.value) {
      return <DropdownItem onClick={this.editDocument}>Editera dokument</DropdownItem>
    }
    else {
      return (null);
    }
  }

  getDownloadPdf = () => {
    const meta = this.props.metadata;
    if (meta.Mimetype && meta.Mimetype.value && meta.Mimetype.value != "application/pdf") {
      return <DropdownItem onClick={this.download_pdf}>{this.strings['Download'] + " pdf"}</DropdownItem>
    }
    else {
      return (null);
    }
  }

  viewLastVersion = () => {

  }

  render() {
    return (
      <Fragment>
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret>
           {this.strings['Actions']}
        </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.editMetadata}>{this.strings['UpdateMetadata']}</DropdownItem>
            <DropdownItem onClick={this.save}>{this.strings['UpdateDocument']}</DropdownItem>
            {this.getEdit()}
            <DropdownItem divider />
            <DropdownItem onClick={this.download_orginial}>{this.strings['Download'] + " orginal"}</DropdownItem>
            {this.getDownloadPdf()}
            <DropdownItem divider />
            <DropdownItem onClick={this.showVersion}>{this.strings['ViewLastVersion']}</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.delete}>{this.strings['DeleteDocument']}</DropdownItem>
            <DropdownItem onClick={this.deleteVersion}>{this.strings['DeleteVersion']}</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.audit}>{this.strings['Auditlog']}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <EditMetadataModal ref={this.modal} filename={this.filename} metadata={this.props.metadata}
          updateDocument={this.props.updateDocument} strings={this.strings} />
        <AuditLogModal ref={this.auditModal} audit={this.props.audit} strings={this.strings} />
      </Fragment>
    );
  }
}