import React, { useState, Fragment } from 'react';
import { Card, CardBody, CardTitle, CardText, Row, Col, Button, Tooltip } from 'reactstrap';
import ReactPlayer from 'react-player'
import { PDFObject } from 'react-pdfobject'
import MetadataForm from '../container/MetadataForm';




const ContentViewer = ({ url, documents, metadata, index, strings, updateUrl} ) => {

const [pdfSize, setPdfSize] = useState(9);
const [metadataSize, setMetadataSize] = useState(3);
const [pdfChar, setPdfChar] = useState('+');
const [tooltipPrev, setTooltipPrev] = React.useState(false);
const [tooltipPrevShown, setTooltipPrevShown] = React.useState(false);
const [tooltipNext, setTooltipNext] = React.useState(false);
const [tooltipNextShown, setTooltipNextShown] = React.useState(false);
const [tooltipSize, setTooltipSize] = React.useState(false);


const getViewer = () => {
    const mimeType = metadata['Mimetype'].value;
    if (url.startsWith("error://")) {
        return (<p><b>{url.replace("error://", "")}</b></p>);
    }
    else if (mimeType === "application/pdf") {
        return (<PDFObject url={url} fallbackLink={strings.fallbackLink} height="1200px" width="100%"/>)
    }
    else if (mimeType.indexOf("video/") != -1) {
       return(<ReactPlayer controls playsinline playing url={url} />)
    }
    else if (mimeType.indexOf("image/") != -1) {
        return(<img src={url} style={{ maxWidth: "100%" }}/>)
     }
     else if (mimeType.indexOf("text/csv") != -1) {
        return(<embed type="text/plain" src={url} width="500" height="200"></embed>)
     }
     else if (mimeType.indexOf("text/") != -1) {
        return(<iframe src={url} style={{ width: "100%", height: "100%", scrolling:"yes"}}></iframe>)
     }
     else if (url.endsWith("pdf")){
        return (<PDFObject url={url} fallbackLink={strings.fallbackLink} height="1200px" width="100%" />);
     }
    else {
        return (<p><b>{"Document av typ " + mimeType + " " + strings.NoRender}</b></p>);
    }
}

const getNewDocument = step => {
    const new_findex = metadata._findex.value + step;
    if (new_findex >=0 && new_findex < documents.length) {
        updateUrl(documents[new_findex]);
    }
}

const changeSize = () => {
    if (pdfSize < 12 ) {
        setPdfSize(12);
        setMetadataSize(0);
        setPdfChar('-');
    }
    else {
        setPdfSize(9);
        setMetadataSize(3);
        setPdfChar('+');
    }
}


    if (documents && documents.length > 0) 
    return (
        <Fragment>
            <Row>
                <Col className="d-flex d-lg-flex mb-3 justify-content-begin">
                    <Button id="prev" className="mr-1" onClick={() => { getNewDocument(-1); }} color="info">&lt;</Button>{' '}
                    <Button id="next" className="mr-3" onClick={() => { getNewDocument(+1); }} color="info">&gt;</Button>{' '}
                    {strings.StepWithButtons}
                </Col>                
                <Col className="d-flex d-lg-flex mb-3 justify-content-end">
                    <Button id="changeSize" className="mr-1" onClick={() => { changeSize(); }} color="info">{pdfChar}</Button>{' '}
                </Col>
            </Row>
            <Row className="d-flex d-lg-flex ">
                <Col key={1} xs={{ order: 1 }} md={{ size: pdfSize, order: 1 }} tag="aside">
                    {getViewer()}
                </Col>
                <Col key={2} xs={{ order: 2 }} md={{ size: metadataSize, order: 2 }} tag="aside">
                    <MetadataForm/>
                </Col>
            </Row>
            <Tooltip
                isOpen={tooltipPrev}
                placement="left"
                target="prev"
                toggle={() => { setTooltipPrev(!tooltipPrev&&!tooltipPrevShown); setTooltipPrevShown(true); }}>
                {strings.TooltipPrev}
            </Tooltip>
            <Tooltip
                isOpen={tooltipNext}
                placement="right"
                target="next"
                toggle={() => { setTooltipNext(!tooltipPrev&&!tooltipNextShown); setTooltipNextShown(true); }}>
                {strings.TooltipNext}
            </Tooltip>
            <Tooltip
                isOpen={tooltipSize}
                placement="right"
                target="changeSize"
                toggle={() => { setTooltipSize(!tooltipSize) }}>
                { pdfSize === 12? strings.TooltipShowMetadata:strings.TooltipHideMetadata}
            </Tooltip>
        </Fragment >
    )
    else return (
        <Card>
            <CardBody>
                <CardTitle tag="h4">{strings.ViewTitle}</CardTitle>
                <CardText>
                    {strings.ViewText1}:
                    <ol>
                        <li>{strings.ViewList1}</li>
                        <li>{strings.ViewList2}</li>
                        <li>{strings.ViewList3}</li>
                        <li>{strings.ViewList4}</li>
                    </ol>
                    {strings.ViewText2}
                </CardText>
            </CardBody>
        </Card>
    )
}


export default ContentViewer;

/*
https://mmdok.s3.eu-north-1.amazonaws.com/mm0/mm0/ltl51i77-lt4wqe3v-Transaktioner_2024-02-27_22-55-27.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5O6VQL3FFUR5MW7K%2F20240310%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20240310T143152Z&X-Amz-Expires=30&X-Amz-Signature=262a7e7237627170e94a1c02f6ca4717f8091fe69c4049f14ae17413eeedd02f&X-Amz-SignedHeaders=host&response-content-type=text%2Fcsv

https://mmdok.s3.eu-north-1.amazonaws.com/mm0/mm0/ltl4z4t8-text.txt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5O6VQL3FFUR5MW7K%2F20240310%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20240310T143335Z&X-Amz-Expires=30&X-Amz-Signature=53a76a0a68af0ecac0892a9a78892dc3dde1d334f6d4e92696c3b9e21355c4b3&X-Amz-SignedHeaders=host&response-content-type=text%2Fplain

*/