import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Card, CardBody, CardTitle, CardText, Row, Col, Button, Tooltip } from 'reactstrap';
import MetadataForm from '../container/MetadataForm';
import { Editor } from '@tinymce/tinymce-react';
import HttpClient from '../../utils/HttpClient';

/*
TODO.
1. Spara dokument i samma version om sådan finns
2. Olika filnamn med slumpmässig suffix
3. Visa bara uppdatera i menyn för Tiny-dokument, inte för andra
*/
const ContentEditor = ({ url, isExistingTinyDoc, metadata, strings } ) => {

  const HEADER = "<!DOCTYPE html><html><body>";
  const FOOTER = "</body></html>";
  
  const editorRef = useRef(null);
  const metadataRef = useRef(null);

  const [pdfSize, setPdfSize] = useState(9);
  const [metadataSize, setMetadataSize] = useState(3);
  const [pdfChar, setPdfChar] = useState('+');
  const [tooltipPrev, setTooltipPrev] = React.useState(false);
  const [tooltipPrevShown, setTooltipPrevShown] = React.useState(false);
  const [tooltipNext, setTooltipNext] = React.useState(false);
  const [tooltipNextShown, setTooltipNextShown] = React.useState(false);
  const [tooltipSize, setTooltipSize] = React.useState(false);

  const saveLocalAndRestAPI = async() => {    
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      let content = editorRef.current.getContent();
      content = HEADER + content + FOOTER;
      const file = new File([content], "tiny.html", { type: 'text/html' });
     
      const httpClient = new HttpClient();

      const meta = JSON.parse(metadataRef.current.getMetadata());
      meta.isEditable = true;

      let docId = null;
      if (isExistingTinyDoc && metadata.id && metadata.id.value) {
        docId = metadata.id.value;
      }
      
      httpClient.upload(meta, file, docId)
      .then(result => {
        file.sent = true;
        alert("Dokumentet har lagrats");
      }).catch(error => {
        console.log(JSON.stringify(error));
        if (error.response && error.response.data && error.response.data.message) {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        }
        else {
          console.error("Failed to send file: " + error);
          alert("Misslyckades att skicka");
        } 
      });
      
    }
  };

  const setContentOnInit = async() => { 
    try {
      const response = await fetch(url);
      const text = await response.text();
      if (isExistingTinyDoc) {
        editorRef.current.setContent(text)
      }
      else {
        editorRef.current.setContent('<p></p>')
      }
    }
    catch(error) {
      editorRef.current.setContent('<p>Something unexpected happened with the editor. Please contact support.</p>')
    }
  }


  return (

    <>


           
<Row noGutters className="pt-2 pt-md-5 w-100 px-4 px-xl-0 mb-3 position-relative">
       
   <Col key={1} xs={{ order: 1 }} md={{ size: 8, order: 1 }} tag="aside"
                    className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0">
  <form id = "masoudform2"> 
      <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
    onInit={(evt, editor) => {
        editorRef.current = editor;
        setContentOnInit();
      }
    }


    initialValue={'<p></<p>'}

    init={{
      selector: 'textarea',
      height: 800,
      menubar: true,
      plugins: [
        'advlist','save', 'autolink', 'lists', 'link', 'image', 'charmap',
        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
      ],
      toolbar: 'undo redo | blocks | ' +
        'bold italic forecolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help' ,
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
    }}
     />
</form>

    <tbody>
      <table><tr>
        <td> <button onClick={saveLocalAndRestAPI}>Spara i TinyDS</button></td>
        </tr>
     </table>
    </tbody>
    
</Col>
       <Col key={2} xs={{ order: 2 }} md={{ size: 2, order: 2, offset: 1 }} tag="section"
                    className="py-5 mb-5 py-md-0 mb-md-0">
                   <MetadataForm ref={metadataRef}/>
      </Col>

</Row>
       
   
</>
 )
}


export default ContentEditor;
