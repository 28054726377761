import { connect } from 'react-redux'
import Filelist from '../presentation/Filelist'
import { showErrorMessage } from '../../actions/DocumentAction'

const mapStateToProps = (state, ownProps) => {
  return {
    strings: state.i18.strings
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      showErrorMessage: (message) => {
        dispatch(showErrorMessage(message));
      }
    }
  }


const FilelistView = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Filelist)

export default FilelistView;