import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ActionMenu from './ActionMenu';
import UserMessage from '../../utils/UserMessage';

class MetadataView extends React.Component {

  constructor(props) {
    super(props);
    this.strings = props.strings;
    this.state = {
      modal: false,
      size: 'modal-xl',
      body: ''
    };
  }

  toggle = (metadata, audit, strings) => {
    this.setState({
      modal: true,
      body: this.getMetadataTable(metadata)
    });
    this.metadata = metadata;
    this.audit = audit;
    this.strings = strings;
  }

  updateDocument = (metadata) => {
    this.metadata = metadata;
    this.setState({ body: this.getMetadataTable(metadata) });
    this.props.updateDocument(metadata);
  }

  saveDocument = (metadata) => {
    this.props.saveDocument(metadata);
  }

  showDocument = async () => {
    const metadata = this.metadata;
    this.props.showDocument(metadata);
    this.hide();
  }

  showVersion = async (metadata, version) => {
    this.props.showDocument(metadata, version);
    this.hide();
  }

  deleteDocument = (metadata) => {
    this.props.deleteDocument(metadata);
    this.hide();
  }

  editDocument = (metadata) => {
    this.props.editDocument(metadata);
    this.hide();
  }

  getTableContent = metadata => {
    const arr = [];
    for (var property in metadata) {
      console.log(property + ":" + metadata[property].value);
      console.log(metadata[property].value == null);
      if (!property.startsWith('_') && property !== 'id')
        arr.push(<tr><td key={property} style={{ textAlign: "right" }}>{property}:</td><td>{metadata[property].value.toString()}</td></tr>);
    }
    return arr.map((row, index) => {
      return row
    })
  }

  getMetadataTable = metadata => {
    return <table>
      <tbody>
        {this.getTableContent(metadata)}
      </tbody>
    </table>
  }

  hide = () => {
    this.setState({
      modal: false
    });
  }

  getMetadata = () => {
    const metadata = this.metadata;
    return metadata;
  }

  getActionMenu = () => {
    return (<ActionMenu metadata={this.getMetadata()} audit={this.audit} updateDocument={this.updateDocument} 
    saveDocument={this.saveDocument} deleteDocument={this.deleteDocument} showVersion={this.showVersion} 
    editDocument={this.editDocument} strings={this.strings}/>);
  }

  render = () =>
  (
    <div>
      <Modal isOpen={this.state.modal} close={this.hide} toggle={this.toggle} className={this.props.className} backdrop={false}>
        <ModalHeader>Metadata</ModalHeader>
        <ModalBody>
          {this.state.body}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.showDocument}>{this.strings['ShowDocument']}</Button>{' '}
          {this.getActionMenu()}
          <Button color="secondary" onClick={() => this.hide()}>{this.strings['Close']}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MetadataView;
