const ActionTypes = {
    CHANGE_PAGE: "CHANGE_PAGE",
    CHANGE_TAB: "CHANGE_TAB",
    DOCUMENT_COUNT: "DOCUMENT_COUNT",
    EXPORT_DIP_SUCCESS: "EXPORT_DIP_SUCCESS",
    EXPORT_DIP_FAILURE: "EXPORT_DIP_FAILURE",
    SHOW_DOCUMENT: "SHOW_DOCUMENT",
    FILTER_DOCUMENT_LIST: "FILTER_DOCUMENT_LIST",
    SORT_DOCUMENTS: "SORT_DOCUMENTS",
    DELETE_DOCUMENT:"DELETE_DOCUMENT",
    UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
    CHANGE_DOCTYPE: "CHANGE_DOCTYPE",
    CHANGE_UPLOADTYPE: "CHANGE_UPLOADTYPE",
    LOAD_DOCUMENTS_SUCCESS: "LOAD_DOCUMENTS_SUCCESS",
    LOAD_DOCUMENTS_FAILURE: "LOAD_DOCUMENTS_FAILURE",
    LOAD_CREATE_TYPES_SUCCESS: "LOAD_CREATE_TYPES_SUCCESS",
    LOAD_SEARCH_TYPES_SUCCESS: "LOAD_SEARCH_TYPES_SUCCESS",
    LOAD_TYPES_FAILURE: "LOAD_TYPES_FAILURE",
    GET_DOCUMENT_URL_FAILURE: "GET_DOCUMENT_URL_FAILURE",
    DOCUMENT_URL: "DOCUMENT_URL",
    CHANGE_LANG: "CHANGE_LANG",
    UPDATE_CONTENT: "UPDATE_CONTENT",
    SHOW_ERROR_MESSAGE: "SHOW_ERROR_MESSAGE"
};

export default ActionTypes;