import React from 'react'

const Footer = ({strings, message, msgtype}) => {
    const getMessage = () => {
        if (message) {
            let msg = message;
            const color = msgtype===0? '#50C878':'#CD5C5C'; 
            const style = 'background-color: ' + color;
            if (msg.includes('refresh token')) msg = strings['SessionExpired'];
            return (
                <div className="text-center p-4 text-light" Style={style}>
                    {msg}
                </div>
            )
        }
        return (null);
    }
    return  (
        <div style={{ paddingTop: 20 }}>
          
        <footer className="text-center text-lg-start bg-light text-muted">
                {getMessage()}

              
            <div className="text-center p-4" Style="background-color: rgba(0, 0, 0, 0.05);">
                <a href="https://www.tinyds.se/" target="_blank">TinyDS</a><br/>   
                {strings['FooterText1']}<br/>    
                {strings['Contact']}: info@tinyds.se
            </div>
        </footer>
        </div>
    )
}

export default Footer;
