import React from 'react';
import { Container } from 'reactstrap';
import Footer from '../container/Footer'
import Header from '../container/Header'
import ContentEditorView from '../container/ContentEditor';

const EditorCreateTab = () => {
    return (
        <Container className="px-0">
            <Header />
            <ContentEditorView/>
            <Footer/>
        </Container >
    )
}
export default EditorCreateTab;