import ActionTypes from './Types';
import HttpClient  from '../utils/HttpClient';

export function getSearchTypes() {
  return dispatch => new HttpClient().types("search")
    .then(
      data => dispatch({ type: 'LOAD_SEARCH_TYPES_SUCCESS', data }),
      err => dispatch({ type: 'LOAD_TYPES_FAILURE', err })
    )
  }

  export function getCreateTypes() {
    return dispatch => new HttpClient().types("create")
      .then(
        data => dispatch({ type: 'LOAD_CREATE_TYPES_SUCCESS', data }),
        err => dispatch({ type: 'LOAD_TYPES_FAILURE', err })
      )
    }

export const changeDocumentType = (docType) => ({
  type: ActionTypes.CHANGE_DOCTYPE,
  payload: docType
})

export const changeUpdateType = (docType) => ({
  type: ActionTypes.CHANGE_UPLOADTYPE,
  payload: docType
})


