import React, { Fragment, useRef } from 'react';
import MetadataView from './MetadataView';

import {
  Table
} from 'reactstrap';


const MAX_VISIBLE_COLUMNS = 4;
const getNumberOfColumns = () => {
  const nColumns = Math.ceil(window.innerWidth/250);
  return (nColumns > MAX_VISIBLE_COLUMNS)? MAX_VISIBLE_COLUMNS : nColumns;
}

const Documentlist = ({ documents, showDocument, editDocument, sort, update, save, strings, remove }) => {

  const metadataView = useRef();
  const getDocuments = () => documents == null ? [] : documents;

  const getKeys = () => {
    const docs = getDocuments();
    const firstObject = docs.length > 0 ? docs[0] : 0;
    const entries = Object.entries(firstObject);
    const sorted = entries.sort((a, b) => {
      let retval = 0;
      if (a[1].general && !b[1].general) retval = -1;
      else if (b[1].general && !a[1].general) retval = 1;
      else if (b[1].order && b[1].order) retval = a[1].order - b[1].order
      return retval;
    });
    const keys = [];
    for (let i=0; i<sorted.length; i++) {
      if (!sorted[i][0].startsWith('_'))
        keys.push(sorted[i][0])
    }
    return keys;
  }

  const getHeader = () => {
    const nColumns = getNumberOfColumns();
    var keys = getKeys();
    return keys.map((key, index) => {
      if (index < nColumns) 
        return <th onClick={e => handleHeaderClick(e, index)} key={key}>{key.toUpperCase()}</th>
      else
        return (null);
    })
  }

  const getRows = () => {
    const keys = getKeys();
    return getDocuments().map((row, index) => {
      return <tr key={index}><RenderRow key={index} row={index} data={row} keys={keys} callback={handleRowClick} />
      </tr>
    })
  }

  const handleHeaderClick = (e, index) => {
    e.preventDefault();
    const column = getKeys()[index];
    sort(column);
  }

  const handleRowClick = (rowIndex) => {
    const o = documents[rowIndex];
    const metadata = {};
    for (let property in o) {
      if (!o.hasOwnProperty(property)) continue;
      metadata[property] = o[property];
    }
    const audit = (o._audit != null && o._audit.value != null)? o._audit : [];
    metadataView.current.toggle(metadata, audit, strings);
  }

  const updateDocument = (metadata) => {
    console.log(metadata['_index']);
    update(metadata);
  }

  const saveDocument = (metadata, id) => {
    //console.log(metadata['_index']);
    save(metadata, id);
  } 

  const deleteDocument = (metadata) => {
    console.log(metadata['_index']);
    remove(metadata);
  }

  return (
    <Fragment>
      <div>
        <Table striped hover>
          <thead>
            <tr>{getHeader()}</tr>
          </thead>
          <tbody>
            {getRows()}
          </tbody>
        </Table>
      </div>
      <MetadataView ref={metadataView} strings={strings} showDocument={showDocument} 
                                       updateDocument={updateDocument} saveDocument={saveDocument} 
                                       deleteDocument={deleteDocument} editDocument={editDocument}/>
    </Fragment>
  );
}

const tdStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word'
};
const RenderRow = (props) => {
  const nColumns = getNumberOfColumns();
  return props.keys.map((key, index) => {
    if (index < nColumns) {
      const value = props.data[key] != null ? props.data[key].value : "";
      return <td key={key} style={tdStyle} onClick={e => props.callback(props.row)}>{String(value)}</td>
    }
    else
      return (null);
  })
}

export default Documentlist;