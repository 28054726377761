import React, {Fragment, useRef} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { isBrowser } from 'react-device-detect';
import Center from 'react-center';
import Footer from '../container/Footer'
import Header from '../container/Header';
import MetadataForm from '../container/MetadataForm';
import Filelist from '../container/Filelist';

const WriteTab = (metadata) => {
    const metadataForm = useRef();

    const getMetadata = () => {
        return metadataForm.current.getMetadata();
    }

    const getDocId = () => {
        return metadataForm.current.getDocId();
    } 

    const getMetadataRealms = () => {
        return metadataForm.current.getMetadataRealms();
    }

        const getMobileView = () => {
            return (
                <Fragment>
                <Center>
                    <MetadataForm ref={metadataForm} metadata={metadata}/>
                </Center>
                <Center>
                    <Filelist metadata={getMetadata} docid={getDocId} metadataRealms={getMetadataRealms}/>
                </Center>
                </Fragment>
            )
        }
    
        const getBrowserView = () => {
            return (
                <Row noGutters className="pt-2 pt-md-5 w-100 px-4 px-xl-0 mb-3 position-relative">
                <Col key={1} xs={{ order: 1 }} md={{ size: 3, order: 1 }} tag="aside"
                    className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0">
                    <MetadataForm ref={metadataForm} metadata={metadata}/>
                </Col>
                <Col key={2} xs={{ order: 2 }} md={{ size: 7, order: 2, offset: 1 }} tag="section"
                    className="py-5 mb-5 py-md-0 mb-md-0">
                    <Filelist metadata={getMetadata} docid={getDocId} metadataRealms={getMetadataRealms}/>
                </Col>
            </Row>
            )
        }
    
        const getView = () => {
            return isBrowser? getBrowserView() : getMobileView();
        }

    return (
        <Container className="px-0">
            <Header />
            {getView()}
            <Footer/>
        </Container>
    )
}

export default WriteTab;