import React from 'react';
import { WRITE_TAB, VIEW_TAB, EDITOR_TAB } from '../../constants';
import SearchTab from './SearchTab';
import WriteTab from './WriteTab';
import ViewTab from './ViewTab';
import EditorCreateTab from './EditorCreateTab';


const ContentPane = ({ activeTab, metadata }) => {
    console.log("--ACTIVE TAB RECEIVED...:" + activeTab);
    if ( activeTab  === WRITE_TAB) {
        return <WriteTab />
    }
    else if ( activeTab  === VIEW_TAB) {
        return <ViewTab/>
    }
     
  else if ( activeTab  === EDITOR_TAB) {
        return <EditorCreateTab/>
    }

    else  {
        return (<SearchTab/>)
    }
}

export default ContentPane;

